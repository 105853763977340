const React = require('react');
const PropTypes = require('prop-types');
const BankTransferPayment = require('./BankTransferPayment');
const CreditCardPayment = require('./CreditCardPayment');
const FAQFooter = require('../faq_footer');

class Payment extends React.Component {
  selectPaymentType(e, type) {
    e.preventDefault();
    this.props.changePaymentType(type);
  }

  render() {
    const {
      offer,
      client,
      paymentType,
      stripePublicKey,
      onBack,
      onNext,
      faqPath,
      reserve_path,
      payment_intent_path,
      payment_intent_failed_path,
    } = this.props;

    const paymentTypeComponent = paymentType === 'credit card'
      ? (
        <CreditCardPayment
          stripePublicKey={stripePublicKey}
          faqPath={faqPath}
          onBack={onBack}
          onNext={onNext}
          payment_intent_path={payment_intent_path}
          payment_intent_failed_path={payment_intent_failed_path}
          offer={offer}
        />
      ) : (
        <BankTransferPayment
          offer={offer}
          client_email={client.email}
          reserve_path={reserve_path}
          onBack={onBack}
          onNext={onNext}
        />
      );

    return (
      <div className="container basa-payment">
        <div className="stripe-form">
          <div className="row">
            <div className="col-sm-offset-2 col-sm-8">
              <div>
                <h1 className="basa-payment-h1">{I18n.t('payment_process.payment_method.title')}</h1>
              </div>
              <nav className="basa-payment-nav">
                <a className={`basa-payment-nav__credit-card ${paymentType == 'credit card' ? 'basa-payment-nav-selected' : ''}`} onClick={(e) => { this.selectPaymentType(e, 'credit card'); }}>{I18n.t('payment_process.payment_method.credit_card')}</a>
                <a className={`basa-payment-nav__bank-transfer ${paymentType == 'bank transfer' ? 'basa-payment-nav-selected' : ''}`} onClick={(e) => { this.selectPaymentType(e, 'bank transfer'); }}>{I18n.t('payment_process.payment_method.bank_transfer')}</a>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-offset-3 col-sm-6">
              {paymentTypeComponent}
              <FAQFooter faqPath={this.props.faqPath} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Payment.propTypes = {
  offer: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  paymentType: PropTypes.string.isRequired,
  stripePublicKey: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  faqPath: PropTypes.string.isRequired,
  reserve_path: PropTypes.string.isRequired,
  payment_intent_path: PropTypes.string.isRequired,
  payment_intent_failed_path: PropTypes.string.isRequired,
};

module.exports = Payment;
