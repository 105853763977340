const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const OfferShowField = require('../show_field');
const { formatCurrency } = require('../../../lib/currency');

const BookingAmountsAdmin = ({ offer, extraCosts }) => {
  const {
    net_amount_artist,
    net_amount_basa,
    vat_percent_artist,
    vat_percent_basa,
    vat_amount_artist,
    vat_amount_basa,
    gross_amount_artist_with_extra_costs,
    gross_amount_basa,
    total_gross_booking_value_with_extra_costs,
    is_full_payment,
    total_vat_amount,
    currency,
  } = offer;

  const totalGrossBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    total_gross_booking_value_with_extra_costs,
  );
  const netAmountArtist = formatCurrency(
    I18n.locale,
    currency,
    net_amount_artist,
  );
  const vatAmountArtist = formatCurrency(
    I18n.locale,
    currency,
    vat_amount_artist,
  );
  const netAmountBasa = formatCurrency(I18n.locale, currency, net_amount_basa);
  const grossAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_basa,
  );
  const vatAmountBasa = formatCurrency(I18n.locale, currency, vat_amount_basa);
  const grossAmountArtistWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_artist_with_extra_costs,
  );
  const totalVatAmount = formatCurrency(
    I18n.locale,
    currency,
    total_vat_amount,
  );

  return (
    <div className="basa-offersShow-totals">
      <OfferShowField
        className={classNames('offerShowFieldNetAmountBasa', {
          'value-light': !is_full_payment,
          'name-dark': is_full_payment,
        })}
        fieldName={I18n.t('offer.total_booking_value')}
        fieldValue={totalGrossBookingValueWithExtraCosts}
      />
      <OfferShowField
        className="offerShowFieldNetAmountBasa value-light"
        fieldName={I18n.t('offer.artist_net_value')}
        fieldValue={netAmountArtist}
      />
      {!is_full_payment && (
        <OfferShowField
          className="offerShowFieldVatPercentBasa value-light"
          fieldName={I18n.t('offer.artist_vat', { vat: vat_percent_artist })}
          fieldValue={vatAmountArtist}
        />
      )}
      {extraCosts.map(({ id, name, value }) => {
        const formattedValue = formatCurrency(I18n.locale, currency, value);
        return (
          <OfferShowField
            key={id}
            className="offerShowFieldVatPercentBasa value-light"
            fieldName={`Extra cost: ${name}`}
            fieldValue={formattedValue}
          />
        );
      })}
      {!is_full_payment && (
        <OfferShowField
          className="offerShowFieldGrossAmountBasa name-dark"
          fieldName={I18n.t('offer.artist_total_amount_due')}
          fieldValue={grossAmountArtistWithExtraCosts}
        />
      )}
      <OfferShowField
        className="offerShowFieldNetAmountBasa value-light"
        fieldName={I18n.t('offer.basa_net_value')}
        fieldValue={netAmountBasa}
      />
      {!is_full_payment && (
        <OfferShowField
          className="offerShowFieldVatPercentBasa value-light"
          fieldName={I18n.t('offer.basa_vat', { vat: vat_percent_basa })}
          fieldValue={vatAmountBasa}
        />
      )}
      {!is_full_payment && (
        <OfferShowField
          className="offerShowFieldGrossAmountBasa name-dark"
          fieldName={I18n.t('offer.basa_total_amount_due')}
          fieldValue={grossAmountBasa}
        />
      )}
      {is_full_payment && (
        <OfferShowField
          className="offerTotalVatAmount value-light"
          fieldName="Total VAT amount:"
          fieldValue={totalVatAmount}
        />
      )}
      <OfferShowField
        className="name-light"
        fieldName={I18n.t('offer.is_full_payment')}
        fieldValue={is_full_payment === true ? 'Yes' : 'No'}
      />
    </div>
  );
};

BookingAmountsAdmin.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
};

module.exports = BookingAmountsAdmin;
