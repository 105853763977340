const React = require('react');
const PropTypes = require('prop-types');

class YoutubeWidget extends React.Component {
  constructor(props) {
    super(props);
    this.handleRemoveVideo = this.handleRemoveVideo.bind(this);
  }

  handleRemoveVideo(event) {
    event.preventDefault();
    this.props.removeVideo();
  }

  render() {
    let embed = null;
    if (this.props.youtubeId) {
      const id = this.props.youtubeId;
      embed = <iframe className="embed-reponsive-item" src={`https://www.youtube.com/embed/${id}?showinfo=0`} allowFullScreen />;
    }
    return (
      <div className="embed-responsive embed-responsive-16by9">
        {!this.props.show && <a href="#" onClick={this.handleRemoveVideo} className="remove-video-icon" />}
        {embed}
      </div>
    );
  }
}

module.exports = YoutubeWidget;
