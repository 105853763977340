const React = require('react');
const PropTypes = require('prop-types');
const BookingTotalsField = require('./booking_totals_field');
const { formatCurrency } = require('../../lib/currency');

const BookingTotalsFull = ({ offer, skip_vat }) => {
  const {
    gross_amount_basa, vat_percent_basa, vat_amount_basa, currency,
  } = offer;

  const grossAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    skip_vat ? 0 : gross_amount_basa,
  );
  const vatAmountBasa = formatCurrency(I18n.locale, currency, vat_amount_basa);

  return (
    <div className="basa-offersShow-totals">
      <BookingTotalsField
        className="name-dark"
        fieldName={I18n.t('offer.total_amount_to_pay')}
        fieldValue={grossAmountBasa}
      />
      <BookingTotalsField
        className="value-light qa-booking-totals-vat"
        fieldName={I18n.t('offer.vat', {
          vat: skip_vat ? 0 : vat_percent_basa,
        })}
        fieldValue={`${skip_vat ? 0 : vatAmountBasa}`}
        smallHint={I18n.t('offer.reverse_deduction_hint')}
      />
    </div>
  );
};

BookingTotalsFull.propTypes = {
  offer: PropTypes.object.isRequired,
  skip_vat: PropTypes.bool,
};

BookingTotalsFull.defaultProps = {
  skip_vat: false,
};

module.exports = BookingTotalsFull;
