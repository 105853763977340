const React = require('react');
const PropTypes = require('prop-types');

function Number({
  name,
  value,
  onChange,
  label,
  minValue,
  maxValue,
}) {
  const valid_value = value === '' ? '' : +value;
  const nextMin = Math.max(valid_value - 1, minValue);
  const nextMax = Math.min(valid_value + 1, maxValue);

  return (
    <div className="b-input">
      <div className="b-input__top">
        <div className="b-input__label">{label}</div>
      </div>
      <div className="b-input__number-wrapper">
        <button
          className="b-input__number-decrement"
          onClick={(e) => { e.preventDefault(); onChange(nextMin.toString()); }}
        >
          -
        </button>
        <input
          className="b-input__number"
          name={name}
          type="number"
          onChange={(e) => onChange(e.target.value)}
          value={valid_value}
          min={minValue}
          max={maxValue}
        />
        <button
          className="b-input__number-increment"
          onClick={(e) => { e.preventDefault(); onChange(nextMax.toString()); }}
        >
          +
        </button>
      </div>
    </div>
  );
}

Number.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
};

Number.defaultProps = {
  label: undefined,
  value: '',
  minValue: '',
  maxValue: '',
};

module.exports = Number;
