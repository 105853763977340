function queryparameterize(params) {
  return Object.keys(params).map((key) => {
    return `${key}=${params[key]}`;
  }).join('&');
}

// eslint-disable-next-line import/prefer-default-export
export function resourcePath(basePath, action, params = {}) {
  const { id } = params;
  const path = {
    index: basePath,
    new: `${basePath}/new`,
    show: `${basePath}/${id}`,
    edit: `${basePath}/${id}/edit`,
  }[action];

  delete params.id;

  const queryparams = queryparameterize(params);

  if (queryparams) {
    return `${path}?${queryparams}`;
  }

  return path;
}
