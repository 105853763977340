const React = require('react');
const PropTypes = require('prop-types');
const pricingData = require('./pricing.json');
const Modal = require('../ui/Modal');

const initialState = {
  category: null,
  masterTag: null,
  subtype: null,
  isOpen: false,
};

const expensiveCountries = [
  'ie', 'au', 'be', 'nl', 'fr', 'at', 'gb', 'us', 'de',
];

const cheapCountries = [
  'it', 'es', 'pt',
];

class PricingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.onOpen = this.onOpen.bind(this);
  }

  componentDidMount() {
    document.addEventListener('openPricingModal', this.onOpen);
  }

  onOpen(e) {
    const {
      artisticServiceId,
      category,
      masterTag,
      subtype,
      logoUrl,
      artistName,
      anchor,
      countryCode,
    } = e.target.dataset;

    let pricingOption = 'other';
    if (expensiveCountries.includes(countryCode)) {
      pricingOption = 'expensive';
    } else if (cheapCountries.includes(countryCode)) {
      pricingOption = 'cheap';
    }

    let line = pricingData.filter((option) => option.category === category)
      .filter((option) => option.master_tag === masterTag);
    if (subtype) {
      line = line.filter((option) => option.subtype === subtype);
    }
    if (line) {
      line = line[0];
    }

    const formatMap = {
      en: {
        name: 'en-GB',
        currency: 'EUR',
      },
      de: {
        name: 'de-DE',
        currency: 'EUR',
      },
      pt: {
        name: 'pt-PT',
        currency: 'EUR',
      },
    };
    const pricingFormatter = new Intl.NumberFormat(
      formatMap[I18n.locale].name,
      {
        style: 'currency',
        currency: formatMap[I18n.locale].currency,
        minimumFractionDigits: 0,
      },
    );

    if (line) {
      this.setState({
        serviceTitle: line[`service_title_${I18n.locale}`],
        serviceBreakdown: line[`service_breakdown_${I18n.locale}`],
        artisticServiceId,
        logoUrl,
        artistName,
        anchor,
        minPrice: pricingFormatter.format(line[`min_price_${pricingOption}`]),
        maxPrice: pricingFormatter.format(line[`max_price_${pricingOption}`]),
        isOpen: true,
      });
    }
  }

  render() {
    const {
      isOpen,
      serviceTitle,
      minPrice,
      maxPrice,
      serviceBreakdown,
      artisticServiceId,
      category,
      masterTag,
      subtype,
      logoUrl,
      artistName,
      anchor,
    } = this.state;

    const hasPricing = Boolean(serviceTitle && minPrice && maxPrice && serviceBreakdown);

    return (
      <Modal
        onClose={() => this.setState({ isOpen: false })}
        isOpen={isOpen}
        extraClass="b-inquiry-modal"
        overlayExtraClass="b-inquiry-modal-overlay"
      >
        <form
          onSubmit={(e) => { e.preventDefault(); this.onNext(); }}
          className="b-inquiry-modal__form"
        >
          <h3 className="b-pricing-modal__header">
            {I18n.t('pricing_modal.header')}
          </h3>
          <div className="b-pricing-modal__content">
            { hasPricing ? (
              <div className="b-pricing-modal__pricing">
                <div
                  className="b-pricing-modal__copy1"
                  dangerouslySetInnerHTML={{
                    __html: I18n.t(
                      'pricing_modal.copy',
                      {
                        service_title: serviceTitle,
                      },
                    ),
                  }}
                />
                <div
                  className="b-pricing-modal__copy2"
                  dangerouslySetInnerHTML={{
                    __html: I18n.t(
                      'pricing_modal.copy2',
                      {
                        min_price: minPrice,
                        max_price: maxPrice,
                        service_breakdown: serviceBreakdown,
                      },
                    ),
                  }}
                />
              </div>
            ) : (
              <span>No pricing data for this service...</span>
            )}
          </div>
          <div className="b-pricing-modal__disclaimer">
            {I18n.t('pricing_modal.disclaimer')}
          </div>
          <a
            href={anchor}
            onClick={(e) => { e.preventDefault(); this.setState({ isOpen: false }); }}
            className="b-button inquiry-form-button"
            data-artistic-service-id={artisticServiceId}
            data-category={category}
            data-master-tag={masterTag}
            data-subtype={subtype}
            data-logo-url={logoUrl}
            data-artist-name={artistName}
          >
            {I18n.t('pricing_modal.button')}
          </a>
        </form>
      </Modal>
    );
  }
}

module.exports = PricingModal;
