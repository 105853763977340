const React = require('react');
const PropTypes = require('prop-types');
const { setupReactDropzone } = require('../../initializers/setup_dropzone');

class PictureUploader extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddPicture = this.handleAddPicture.bind(this);
    this.handleRemovePicture = this.handleRemovePicture.bind(this);
  }

  componentDidMount() {
    setupReactDropzone(this.refs.dropzone, this.props.imagesIds,
      this.handleAddPicture, this.handleRemovePicture);
  }

  handleAddPicture({ id }) {
    const newImagesIds = this.props.imagesIds.concat({ id });
    this.props.onUpdate({ pictures: newImagesIds });
  }

  handleRemovePicture(id) {
    const newImagesIds = this.props.imagesIds.slice();
    let index;
    for (index = 0; index < newImagesIds.length; index++) {
      if (newImagesIds[index].id == id) break;
    }
    newImagesIds.splice(index, 1); // 1 item to be removed
    this.props.onUpdate({ pictures: newImagesIds });
  }

  render() {
    return (
      <div className="picture-widget">
        <form ref="dropzone" className="dropzone drag-and-drop-box">
          <div className="dz-default dz-message upload-outer-wrapper">
            <div className="add-more-photos-default">
              <div className="add-more-photos-inner">
                <p>+</p>
              </div>
            </div>

            <div className="add-more-photos-default">
              <div className="add-more-photos-inner">
                <p>+</p>
              </div>
            </div>

            <div className="add-more-photos-default">
              <div className="add-more-photos-inner">
                <p>+</p>
              </div>
            </div>

            <div className="add-more-photos-default">
              <div className="add-more-photos-inner">
                <p>+</p>
              </div>
            </div>

          </div>
        </form>
      </div>
    );
  }
}

module.exports = PictureUploader;
