const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const ContextPropTypes = require('../context-prop-types');

const withArtistDashboardContext = require('../with-artist-dashboard-context');
const InputField = require('./InputField');
const PromptMessage = require('./PromptMessage');
const { sanitizeNumericalInput } = require('../../../../../lib/sanitizers');

const ExtraCostsFields = ({ context, onChange }) => {
  const [showPrompt, setShowPrompt] = React.useState(false);
  const {
    extraCosts,
    extraCostsErrors,
    updateExtraCost,
    createExtraCost,
    deleteExtraCost,
    isSaving,
    isOfferSent,
    isEditingSentOffer,
    draftOffer: { currency },
  } = context;

  const isInputDisabled = isOfferSent && !isEditingSentOffer;

  const currencySymbol = I18n.t(`currency.${currency}.symbol`);

  const extraCostsLimit = 5;
  const overLimit = extraCosts.length >= extraCostsLimit;

  const onChangeExtraCostName = (index, name) => {
    const extraCost = extraCosts[index];

    extraCost.name = name;
    updateExtraCost(extraCost);
    onChange();
  };

  const onChangeExtraCostValue = (index, value) => {
    const extraCost = extraCosts[index];

    extraCost.value = value;
    updateExtraCost(extraCost);
    onChange();
  };

  const addExtraCost = () => {
    createExtraCost({ name: '', value: '0' });
    onChange();
  };

  return (
    <>
      {!!extraCosts.length && (
        <div className="row">
          <div className="col-xs-8">
            <div className="b-artist-pricing__label">
              {I18n.t('negotiation_dashboard.artist.extra_costs_incl_vat', {
                currency: currencySymbol,
              })}
            </div>
          </div>
        </div>
      )}
      {extraCosts.map(({ id, value, name }, index) => (
        <div className="row" key={id} style={{ marginBottom: 4 }}>
          <div className="col-xs-6 col-sm-8">
            <InputField
              id={`extra-costs-label-${id}`}
              value={name}
              placeholder={I18n.t(
                'negotiation_dashboard.artist.extra_cost_placeholder',
              )}
              onChange={(event) => onChangeExtraCostName(index, event.target.value)}
              disabled={isInputDisabled}
              error={(extraCostsErrors[id] || {}).name}
              name="extra_cost_field_name"
            />
          </div>
          <div className="col-xs-4 col-sm-3">
            <InputField
              id={`extra-costs-value-${id}`}
              value={value}
              onChange={(event) => onChangeExtraCostValue(
                index,
                sanitizeNumericalInput(event.target.value),
              )}
              placeholder="0"
              disabled={isInputDisabled}
              error={(extraCostsErrors[id] || {}).value}
              name="extra_cost_field_value"
            />
          </div>
          <div className="col-xs-1 col-sm-1">
            {!isInputDisabled && (
              <button
                className="u-unbutton"
                onClick={() => deleteExtraCost(id)}
              >
                <i className="glyphicon glyphicon-remove b-artist-pricing__remove" />
              </button>
            )}
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          {!isInputDisabled && !overLimit && (
            <button
              className={classNames(
                'b-button b-button--inverse b-button--small',
                { 'b-button--disabled': isSaving },
              )}
              style={{ margin: 0 }}
              onClick={() => {
                if (extraCosts.length) addExtraCost();
                else setShowPrompt(true);
              }}
              disabled={!!isSaving}
            >
              {I18n.t('negotiation_dashboard.artist.add_extra_cost')}
            </button>
          )}
          {showPrompt && (
            <PromptMessage
              onClick={() => {
                addExtraCost();
                setShowPrompt(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

ExtraCostsFields.propTypes = {
  context: ContextPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
};

module.exports = withArtistDashboardContext(ExtraCostsFields);
