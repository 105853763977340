const React = require('react');
const PropTypes = require('prop-types');
const AudioSection = require('./audio_section');
const VideoSection = require('./video_section');
const GalleryWidget = require('./gallery_widget');
const PictureFourWidget = require('./picture_four_widget');

class ArtisticServiceWidget extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const widgetType = this.props.section.type;
    let widgetElement = null;

    if (widgetType == 'GallerySection') {
      widgetElement = this.props.show ? <GalleryWidget alt={this.props.alt} imagesIds={this.props.section.pictures} /> : <PictureFourWidget errors={this.props.errors} imagesIds={this.props.section.pictures} onUpdate={this.props.onUpdate} />;
    } else if (widgetType == 'YoutubeSection') {
      widgetElement = <VideoSection videoUrls={this.props.section.video_urls || ''} errors={this.props.section.errors} show={this.props.show} onUpdate={this.props.onUpdate} />;
    } else if (widgetType == 'AudioSection') {
      widgetElement = <AudioSection audioUrls={this.props.section.audio_urls || ''} errors={this.props.section.errors} show={this.props.show} onUpdate={this.props.onUpdate} />;
    }
    return (
      <div className="widget">
        {widgetElement}
      </div>
    );
  }
}

module.exports = ArtisticServiceWidget;
