import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../ui/Button';
import Layout from '../../../ui/Layout';
import Heading from '../../../ui/Heading';

const Confirm = ({ authorType, onConfirm }) => {
  const showGetRecommendations = authorType === 'client';

  return (
    <div>
      <Heading text={I18n.t('negotiation_dashboard.cancelation.confirm.title')} />
      <p>
        {I18n.t(`negotiation_dashboard.cancelation.confirm.description_${authorType}`)}
      </p>
      <Layout.List>
        <Button primary onClick={() => onConfirm({ wantsRecommendations: false })}>
          {I18n.t('negotiation_dashboard.cancelation.confirm.confirm')}
        </Button>
        {showGetRecommendations
          && (
          <Button primary onClick={() => onConfirm({ wantsRecommendations: true })}>
            {I18n.t('negotiation_dashboard.cancelation.confirm.confirm_and_get_recommendations')}
          </Button>
          )}
      </Layout.List>
    </div>
  );
};

Confirm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  authorType: PropTypes.string.isRequired,
};

export default Confirm;
