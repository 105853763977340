/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const BookingTotalsField = require('./booking_totals_field');
const { formatCurrency } = require('../../lib/currency');

const BookingTotalsGrossArtist = ({ offer, extraCosts }) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const {
    gross_amount_artist_with_extra_costs,
    net_amount_artist,
    vat_amount_artist,
    vat_percent_artist,
    currency,
  } = offer;

  const toggleClasses = classNames(
    'col-sm-7 basa-offerField-value b-arrow-toggle',
    {
      'b-arrow-toggle--expanded': showDetails,
    },
  );

  const grossAmountArtistWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_artist_with_extra_costs,
  );
  const netAmountArtist = formatCurrency(
    I18n.locale,
    currency,
    net_amount_artist,
  );
  const vatAmountArtist = formatCurrency(
    I18n.locale,
    currency,
    vat_amount_artist,
  );

  return (
    <>
      <div className="row basa-offerField">
        <div className="col-sm-5 basa-offerField-name value-light">
          {I18n.t('offer.remaining_amount')}
        </div>
        <div
          className={toggleClasses}
          onClick={() => setShowDetails(!showDetails)}
        >
          {grossAmountArtistWithExtraCosts}
          <small>{I18n.t('offer.vat_included_hint')}</small>
          <button className="u-unbutton" id="qa-toggle-breakdown">
            <i className="b-arrow-toggle__arrow" />
          </button>
        </div>
      </div>
      {showDetails && (
        <div style={{ marginBottom: 10 }}>
          <BookingTotalsField
            className="value-light"
            fieldName={I18n.t('offer.artist_net_value')}
            fieldValue={netAmountArtist}
          />
          <BookingTotalsField
            className="value-light"
            fieldName={I18n.t('offer.artist_vat', { vat: vat_percent_artist })}
            fieldValue={vatAmountArtist}
          />
          {!!extraCosts.length && (
            <>
              <BookingTotalsField
                className="value-light"
                fieldName={I18n.t('offer.extra_costs')}
              />
              {extraCosts.map(({ id, name, value }) => {
                const formattedValue = formatCurrency(
                  I18n.locale,
                  currency,
                  value,
                );
                return (
                  <BookingTotalsField
                    key={id}
                    className="value-light name-indented"
                    fieldName={name}
                    fieldValue={formattedValue}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

BookingTotalsGrossArtist.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
};

module.exports = BookingTotalsGrossArtist;
