const React = require('react');
const PropTypes = require('prop-types');

const ReactPlayer = React.lazy(() => import(/* webpackChunkName: "react-player" */ 'react-player'));

const initialState = {
  category: '',
  location: '',
  variant: null,
};

class HomepageCta extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    const {
      experimentId,
    } = this.props;

    if (experimentId) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'optimize.activate' });
      }
      this.intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          const variant = window.google_optimize.get(experimentId);
          this.setState({ variant });
          clearInterval(this.intervalId);
        }
      }, 100);
    }
  }

  render() {
    const {
      variant,
    } = this.state;

    const {
      backgroundUrl,
    } = this.props;

    if (variant !== '1') {
      return null;
    }

    return (
      <div className="b-desktop-landing">
        <div className="b-desktop-landing__wrapper">
          <React.Suspense fallback={<div>Loading...</div>}>
            <ReactPlayer
              url={backgroundUrl}
              playing
              loop
              controls={false}
              volume={0}
              className="b-desktop-landing__player"
              width="100%"
              height="100%"
            />
          </React.Suspense>
          <div className="b-desktop-landing__overlay" />
          <div className="b-desktop-landing__search-container">
            <h1 className="b-desktop-landing__search-title">
              {I18n.t('homepage_cta.search_title')}
            </h1>
            <div className="b-desktop-landing__search-content">
              <a
                id="homepage_cta_button"
                href="#cta"
                className="b-button inquiry-form-button b-button--primary b-button--wide b-desktop-landing__submit"
                data-flow-name="general_cta"
                data-artistic-service-id=""
                data-category=""
                data-master-tag=""
                data-subtype=""
              >
                {I18n.t('homepage_cta.submit')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomepageCta.propTypes = {
  backgroundUrl: PropTypes.string.isRequired,
  experimentId: PropTypes.any.isRequired,
};

export default HomepageCta;
