const React = require('react');
const PropTypes = require('prop-types');
const lozad = require('lozad');

const replaceExtension = (filename, ext) => {
  return filename.replace('.jpg', `.${ext}`);
};

const ArtisticServiceCard = ({ artistic_service }) => {
  const {
    path,
    name,
    main_picture,
    artist_name,
    artist_location,
    admin_stats
  } = artistic_service;

  const observer = lozad();

  React.useEffect(() => {
    observer.observe();
  }, []);

  return (
    <a href={path} target="_blank" rel="noopener noreferrer">
      <div className="service-card">
        <div className="square-image">
          <picture
            className="lozad"
            data-toggle-class="fadeIn"
            data-iesrc={main_picture}
            data-alt={`${name} ${I18n.t('welcome.index.most_popular_services.by')} ${artist_name}`}
          >
            <source type="image/webp" srcSet={replaceExtension(main_picture, 'webp')} />
            <source type="image/jpg" srcSet={main_picture} />
            {/* There's no <img> tag because lozad takes care of it */}
            {/* https://github.com/ApoorvSaxena/lozad.js/#example-with-picture-tag */}
          </picture>
        </div>
        <div className="service-card-content">
          <h3>{name}</h3>
          <p>
            {I18n.t('shared.by')}
            &nbsp;
            {artist_name}
          </p>
          <p className="service-card-location">
            <span className="marker-svg-icon" />
            {artist_location}
          </p>
          {
            admin_stats
            && (
              <p className="service-card__stats">
                <small>
                  <span title="# of forwards; # of accepted; # with artist message; # of offers; # of confirmed">
                    {admin_stats}
                  </span>
                </small>
              </p>
            )
          }
        </div>
      </div>
    </a>
  );
};

ArtisticServiceCard.propTypes = {
  artistic_service: PropTypes.object.isRequired,
};

module.exports = ArtisticServiceCard;
