const React = require('react');
const Slider = require('rc-slider');
const Tooltip = require('rc-tooltip').default;

const RangeSelect = (props) => {
  const [localValue, setLocalValue] = React.useState(props.value);

  const handle = (handleProps) => {
    const {
      value,
      dragging,
      index,
      getContainer,
      ...restProps
    } = handleProps;

    const placement = ['top', 'bottom'][index];

    let label = value;
    if (props.useExclusiveRange && value > props.max - 1) {
      label = `${props.max}+`;
    }

    return (
      <Tooltip
        overlay={label}
        placement={placement}
        key={index}
        overlayClassName="rc-slider-tooltip"
        getTooltipContainer={props.getContainer}
        visible
      >
        <Slider.Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  const onRangeChange = (value) => {
    setLocalValue(value);
    props.onChange(value);
  };

  const onInputChange = (index, value) => {
    const newLocalValue = localValue.slice();

    newLocalValue[index] = value;
    setLocalValue(newLocalValue);
    props.onChange(newLocalValue);
  };

  return (
    <div style={{ padding: '40px 0' }}>
      <input
        type="hidden"
        name="slider_range_min_value"
        value={localValue[0]}
        onChange={(e) => onInputChange(0, +e.target.value)}
      />
      <input
        type="hidden"
        name="slider_range_max_value"
        value={localValue[1]}
        onChange={(e) => onInputChange(1, +e.target.value)}
      />

      <Slider.Range
        {...props}
        handle={handle}
        value={localValue}
        onChange={onRangeChange}
      />
    </div>
  );
};

module.exports = RangeSelect;
