const React = require('react');
const PropTypes = require('prop-types');

const ReactPlayer = React.lazy(() => import(/* webpackChunkName: "react-player" */ 'react-player'));
const Select = require('../ui/Select');
const LocationAutocomplete = require('../LocationAutocomplete').default;

const initialState = {
  category: '',
  location: '',
  variant: null,
};

const labelize = (values_or_value, labels) => {
  if (!values_or_value) return null;

  if (Array.isArray(values_or_value)) {
    return values_or_value.map((value) => ({ value, label: labels[value] }));
  }

  return { value: values_or_value, label: labels[values_or_value] };
};

class DesktopLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    document.addEventListener('openSmartInquiry', this.onOpen);

    if (this.props.experimentId) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'optimize.activate' });
      }
      this.intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          const variant = window.google_optimize.get(this.props.experimentId);
          this.setState({ variant });
          clearInterval(this.intervalId);
        }
      }, 100);
    }
  }

  categoryLabels = () => {
    const { categories } = this.props;

    return categories.reduce((memo, category) => {
      // eslint-disable-next-line no-param-reassign
      memo[category] = I18n.t(`search.categories.${category}`);

      return memo;
    }, {});
  }

  onCategoryChange = (category) => {
    this.setState({ category });
  }

  onSubmit = () => {
    const {
      location,
      category,
    } = this.state;

    const locale = I18n.locale !== 'en' ? `/${I18n.locale}` : '';

    const queryParams = {
      q: '',
      master_tag: 'all',
      location,
      category,
    };

    const queryParamsString = Object.keys(queryParams).map((key) => {
      const value = queryParams[key];

      if (value && value.length) {
        return `${key}=${encodeURIComponent(value)}`;
      }

      return null;
    }).filter((value) => value !== null).join('&');

    const url = `${locale}/search?${queryParamsString}`;
    window.location = url;
  }

  render() {
    if (this.state.variant !== '1') {
      return null;
    }

    const categoryLabels = this.categoryLabels();
    const categories = labelize(this.props.categories, categoryLabels);
    const currentCategory = labelize(this.state.category, categoryLabels);

    const { backgroundUrl } = this.props;

    return (
      <div className="b-desktop-landing">
        <div className="b-desktop-landing__wrapper">
          <React.Suspense fallback={<div>Loading...</div>}>
            <ReactPlayer
              url={backgroundUrl}
              playing
              loop
              controls={false}
              volume={0}
              className="b-desktop-landing__player"
              width="100%"
              height="100%"
            />
          </React.Suspense>
          <div className="b-desktop-landing__overlay" />
          <div className="b-desktop-landing__search-container">
            <h1 className="b-desktop-landing__search-title">
              {I18n.t('desktop_landing.search_title')}
            </h1>
            <div className="b-desktop-landing__search-content">
              <Select
                options={categories}
                value={currentCategory}
                placeholder={I18n.t('desktop_landing.type_of_art')}
                className="b-select b-select--rounded b-desktop-landing__category-filter"
                onChange={({ value }) => this.onCategoryChange(value)}
              />
              <LocationAutocomplete
                google_maps_api_key={this.props.google_maps_api_key}
                placeholder={I18n.t('desktop_landing.location_placeholder')}
                fieldName="search[location]"
                onChange={(location) => this.setState({ location })}
                value={this.state.location}
                searchOptionTypes={['(regions)']}
                inputClassName="b-search-page__input b-desktop-landing__location-input"
                onGeocode={this.onGeocode}
                maxSuggestions={3}
                onSelect={(location) => {
                  if (location === '') {
                    this.setState({ location, country_code: null }, () => {
                      this.handleSubmitForm();
                    });
                  }
                }}
              />
              <button
                type="submit"
                className="b-button b-button--primary b-button--wide b-desktop-landing__submit"
                onClick={this.onSubmit}
              >
                {I18n.t('desktop_landing.submit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DesktopLanding.propTypes = {
  backgroundUrl: PropTypes.string.isRequired,
};

export default DesktopLanding;
