const React = require('react');
const PropTypes = require('prop-types');
const InputField = require('./input_field');

class OptionalInputField extends React.Component {
  constructor(props) {
    super(props);

    const {
      isEnabled = true,
    } = props;

    this.state = {
      isEnabled,
    };
  }

  render() {
    if (!this.state.isEnabled) {
      return null;
    }

    return (
      <div className="row b-optional-input-field">
        <div className="col-sm-12 b-optional-input-field__container">
          <InputField {...this.props} />
        </div>
      </div>
    );
  }
}

module.exports = OptionalInputField;
