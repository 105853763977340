const React = require('react');
const PropTypes = require('prop-types');
const InvoicesTable = require('../../admin/invoices/table');
const BookingConfirmationTable = require('../../admin/booking_confirmations/table');

const { invoicesPath } = require('../../../resources/InvoiceResource');
const { parseErrorResponse } = require('../../../lib/api_errors');

class Actions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSendOfferDisabled: false,
      isConfirmOfferDisabled: false,
      isSendConfirmationToClientDisabled: false,
      isSendConfirmationToArtistDisabled: props.offer.is_full_payment === true,
    };
  }

  onDone = () => {
    window.alert('Done, email sent!');

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  sendOffer = () => {
    if (!window.confirm('This will send an email to the client with the information about the offer, do you want to continue?')) return;

    this.setState({ isSendOfferDisabled: true });

    $.post(this.props.send_offer_path)
      .done(this.onDone)
      .fail((response) => {
        window.alert(parseErrorResponse(response, 'Error sending the offer.'));
        this.setState({ isSendOfferDisabled: false });
      });
  }

  confirmOffer = () => {
    if (!window.confirm('This will send an email to the client confirming that BASA received their payment, do you want to continue?')) return;

    this.setState({ isConfirmOfferDisabled: true });

    $.post(this.props.confirm_offer_path)
      .done(this.onDone)
      .fail((response) => {
        window.alert(parseErrorResponse(response, 'Error confirming the offer.'));
        this.setState({ isConfirmOfferDisabled: false });
      });
  }

  sendConfirmationToClient = () => {
    if (!window.confirm('This will send an email to the client with the booking confirmation and invoice attached, do you want to continue?')) return;

    this.setState({ isSendConfirmationToClientDisabled: true });

    $.post(this.props.send_confirmation_to_client_path)
      .done(this.onDone)
      .fail((response) => {
        window.alert(parseErrorResponse(response, 'Error sending the confirmation.'));
        this.setState({ isSendConfirmationToClientDisabled: false });
      });
  }

  sendConfirmationToArtist = () => {
    if (!window.confirm('This will send an email to the artist with the client billing details and the booking confirmation attached, do you want to continue?')) return;

    this.setState({ isSendConfirmationToArtistDisabled: true });

    $.post(this.props.send_confirmation_to_artist_path)
      .done(this.onDone)
      .fail((response) => {
        window.alert(parseErrorResponse(response, 'Error sending the confirmation.'));
        this.setState({ isSendConfirmationToArtistDisabled: false });
      });
  }

  renderInvoicesTable() {
    const {
      invoices,
    } = this.props;

    if (!invoices.length) return null;

    return (
      <div>
        <h4>Invoices</h4>
        <InvoicesTable invoices={invoices} />
      </div>
    );
  }

  renderBookingConfirmationTable() {
    const {
      booking_confirmation_id,
      booking_confirmation,
      offer,
    } = this.props;

    if (!booking_confirmation_id) return null;

    return (
      <div>
        <h4>Booking confirmation</h4>
        <BookingConfirmationTable
          booking_confirmation_id={booking_confirmation_id}
          booking_number={offer.booking_number}
          booking_confirmation={booking_confirmation}
        />
      </div>
    );
  }

  renderStatusTable() {
    const isSendOfferDisabled = this.state.isSendOfferDisabled ? 'disabled' : '';
    const isConfirmOfferDisabled = this.state.isConfirmOfferDisabled ? 'disabled' : '';
    const isSendConfirmationToArtistDisabled = this.state.isSendConfirmationToArtistDisabled ? 'disabled' : '';
    const isSendConfirmationToClientDisabled = this.state.isSendConfirmationToClientDisabled ? 'disabled' : '';

    const {
      offer,
      payment_url,
      invoices,
      booking_confirmation,
      client,
    } = this.props;

    const {
      id,
      status,
    } = offer;

    const isOfferSent = status !== 'created';
    const isPaid = status === 'paid_with_cc' || status === 'paid_with_bt';
    const isReserved = status === 'reserved';
    const isInvoiceSent = invoices.find((invoice) => invoice.sent);
    const isInvoiceCreated = invoices.length > 0;
    const isArtistConfirmationSent = (booking_confirmation && booking_confirmation.sent_to_artist_at);

    return (
      <div>
        <h4>Offer flow</h4>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>Created</th>
              <th>Sent to Client</th>
              <th>Paid</th>
              <th>Client Invoice</th>
              <th>Artist Confirm.</th>
            </tr>
            <tr>
              <td className="success">Yes</td>
              <td className={isOfferSent ? 'success' : ''}>
                {isOfferSent ? 'Yes' : 'No'}
              </td>
              <td className={isPaid ? 'success' : ''}>
                {isPaid ? 'Yes' : isReserved ? 'Reserved' : 'No'}
              </td>
              <td className={`offer-flow__client-confirmation ${isInvoiceSent ? 'success' : ''}`}>
                {isInvoiceSent ? 'Sent' : isInvoiceCreated ? 'Created' : '-'}
              </td>
              <td className={`offer-flow__artist-confirmation ${isArtistConfirmationSent ? 'success' : ''}`}>
                {isArtistConfirmationSent ? 'Sent' : booking_confirmation ? 'Created' : '-'}
              </td>
            </tr>
            <tr className="b-admin__actions">
              <td>
                <a href={this.props.edit_path}>Edit Offer</a>
              </td>
              <td>
                {(!isPaid && !isReserved)
                  && (
                  <button
                    onClick={this.sendOffer}
                    disabled={isSendOfferDisabled}
                  >
                    Send offer to {client.email}
                  </button>
                  )}
              </td>
              <td>
                <a href={payment_url}>Payment link</a>
                {isReserved
                  && (
                    <button
                      onClick={this.confirmOffer}
                      disabled={isConfirmOfferDisabled}
                      type="button"
                    >
                      Confirm bank transfer
                    </button>
                  )}
              </td>
              <td>
                {(isPaid || isReserved) && <a href={invoicesPath('new', { offer_id: id })}>New invoice</a>}
                {((isPaid || isReserved) && !isInvoiceSent)
                  && (
                    <button
                      onClick={this.sendConfirmationToClient}
                      disabled={isSendConfirmationToClientDisabled}
                    >
                      Send confirm. and invoice to client
                    </button>
                  )}
              </td>
              <td>
                {(isPaid || isReserved)
                  && (
                    <button
                      onClick={this.sendConfirmationToArtist}
                      disabled={isSendConfirmationToArtistDisabled}
                      type="button"
                    >
                      Send confirm. to artist
                    </button>
                  )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderStatusTable()}
        {this.renderBookingConfirmationTable()}
        {this.renderInvoicesTable()}
      </div>
    );
  }
}

Actions.propTypes = {
  confirm_offer_path: PropTypes.string.isRequired,
  edit_path: PropTypes.string.isRequired,
  send_confirmation_to_client_path: PropTypes.string.isRequired,
  send_confirmation_to_artist_path: PropTypes.string.isRequired,
  send_offer_path: PropTypes.string.isRequired,
  payment_url: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  invoices: PropTypes.array.isRequired,
  booking_confirmation_id: PropTypes.number,
  booking_confirmation: PropTypes.object,
};

Actions.defaultProps = {
  booking_confirmation_id: null,
  booking_confirmation: null,
};

module.exports = Actions;
