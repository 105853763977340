const slugify = require('slugify');

/**
 * Generates slugs for titles suffixing duplicates numerically.
 *
 * Ex:
 *
 *  slugger.slugFor('yolo yolo') // "yolo-yolo"
 *  slugger.slugFor('yolo yolo') // "yolo-yolo-1"
 *  slugger.slugFor('yolo yolo') // "yolo-yolo-2"
 */
module.exports = class Slugger {
  constructor() {
    this._cache = {};
  }

  slugFor(title) {
    let slug = this._slugify(title);

    if (this._doesExist(slug)) {
      slug = this._appendSuffix(slug);
    }

    this._cache[slug] = true;

    return slug;
  }

  _slugify = (title) => slugify(title, { replacement: '-', lower: true })

  _appendSuffix(slug) {
    let suffix = 0;
    let suffixedSlug = slug;

    while (this._doesExist(suffixedSlug)) {
      suffix += 1;
      suffixedSlug = `${slug}-${suffix}`;
    }

    return suffixedSlug;
  }

  _doesExist(slug) {
    return Boolean(this._cache[slug]);
  }
};
