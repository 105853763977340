const React = require('react');
const PropTypes = require('prop-types');

const { sumValues } = require('../../../lib/calculations');

const ExtraCosts = ({
  extraCosts,
  onChange,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const onChangeAttribute = (index, attribute, value) => {
    const updatedExtraCosts = extraCosts.slice();
    const recalculate = ['value', '_destroy'].includes(attribute);

    updatedExtraCosts[index][attribute] = value;
    onChange(updatedExtraCosts, recalculate);
  };

  return (
    <>
      <div className="row">
        <div className="form-group">
          <div className="col-xs-3 col-xs-offset-9">
            <label className="b-amount-calculator__label" htmlFor="amount_extra_costs">
              <span>Amount Extra costs (EUR)</span>
              <button
                onClick={(e) => { e.preventDefault(); setExpanded(!expanded); }}
                style={{ marginLeft: 4 }}
              >
                {expanded ? 'Collapse' : 'Expand' }
              </button>
            </label>
            <input
              id="amount_extra_costs"
              className="form-control"
              value={sumValues(extraCosts)}
              readOnly
            />
          </div>
        </div>
      </div>

      {expanded
        && (
        <>
          <div className="row">
            <div className="col-xs-3" />
            <div className="col-xs-3"><strong>Delete</strong></div>
            <div className="col-xs-3"><strong>Extra cost name</strong></div>
            <div className="col-xs-3"><strong>Extra cost value</strong></div>
          </div>
          {[0, 1, 2, 3, 4].map((index_id) => {
            const extraCost = extraCosts[index_id] || {};

            return (
              <div key={index_id} className="row">
                <div className="form-group">
                  <div className="col-xs-3" />
                  <div className="col-xs-3">
                    <input
                      id={`extra_cost_destroy_${index_id}`}
                      disabled={!extraCost.id}
                      type="checkbox"
                      style={{ marginTop: 15 }}
                      // eslint-disable-next-line no-underscore-dangle
                      checked={extraCost._destroy || false}
                      onChange={({ target }) => onChangeAttribute(index_id, '_destroy', target.checked)}
                    />
                  </div>
                  <div className="col-xs-3">
                    <input
                      id={`extra_cost_name_${index_id}`}
                      className="form-control"
                      value={extraCost.name}
                      onChange={({ target }) => onChangeAttribute(index_id, 'name', target.value)}
                    />
                  </div>
                  <div className="col-xs-3">
                    <input
                      id={`extra_cost_value_${index_id}`}
                      className="form-control"
                      type="number"
                      value={extraCost.value}
                      onChange={({ target }) => onChangeAttribute(index_id, 'value', target.value)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="row"><div className="col-xs-12"><br /></div></div>
        </>
        )}
    </>
  );
};

ExtraCosts.propTypes = {
  extraCosts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

module.exports = ExtraCosts;
