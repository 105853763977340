const React = require('react');

class SelectBox extends React.Component {
  constructor() {
    super();

    this.state = {
      isVisible: false,
    };
  }

  _prepareOptions() {
    const {
      options,
    } = this.props;

    return Object.entries(options).map(([key, value], index) => (
      <span
        role="option"
        tabIndex={index === 0 ? '0' : '-1'}
        value={key}
        key={index}
        onClick={this._onSelectOptionClick.bind(this, key)}
        onKeyPress={this._onSelectOptionClick.bind(this, key)}
      >
        {value}
      </span>
    ));
  }

  _onSelectOptionClick(value) {
    this.props.onChangeValue(value);

    this.setState({
      isVisible: false,
    });
  }

  _onSelectClick() {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  }

  _classNameForSelector() {
    if (this.state.isVisible) return 'select__selector select__selector--opened';
    return 'select__selector';
  }

  _classNameForSelectValue() {
    if (this.props.selected != '') return 'select__value select__value--selected';
    return 'select__value select__value--empty';
  }

  _selectedValue() {
    const {
      options,
      selected,
      placeholder,
    } = this.props;

    if (selected !== '') {
      return options[selected];
    }

    return placeholder;
  }

  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        <div className="select">
          <div className={this._classNameForSelector()} onClick={this._onSelectClick.bind(this)}>
            <span className={this._classNameForSelectValue()}>
              {this._selectedValue()}
            </span>
          </div>
          {this.state.isVisible
            && (
            <div className="select__list">
              {this._prepareOptions()}
            </div>
            )}
        </div>
      </div>
    );
  }
}

module.exports = SelectBox;
