const React = require('react');
const PropTypes = require('prop-types');

class AddWidgetButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-inline">
        <button onClick={this.props.onClick} className="btn btn-default"> Add Section </button>
        <select onChange={this.props.onSelect} className="form-control">
          <option value="GallerySection">Gallery</option>
          <option value="YoutubeSection">Youtube</option>
          <option value="AudioSection">Audio</option>
        </select>
      </div>
    );
  }
}

module.exports = AddWidgetButton;
