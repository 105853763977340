const React = require('react');

const FAQFooter = (props) => {
  return (
    <div className="b-payment__info">
      {I18n.t('payment_process.payment_method.any_questions_first')}
      &nbsp;<a target="_blank" href={props.faqPath} className="green-link" rel="noreferrer">FAQ</a>&nbsp;
      {I18n.t('payment_process.payment_method.any_questions_last')}
      &nbsp;
      <a href="mailto:support@bookastreetartist.com" className="green-link" target="_top">support@bookastreetartist.com</a>
    </div>
  );
};

module.exports = FAQFooter;
