const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const HelpTooltip = require('../ui/HelpTooltip');

function HorizontalField({
  fieldName,
  fieldValue,
  optional,
  highlightValue,
  italicValue,
  tip,
}) {
  if (optional && !fieldValue) {
    return null;
  }

  const valueClassName = classNames('col-xs-8 b-inquiry-preview__field-value', {
    'b-inquiry-preview__field-value--highlighted': highlightValue,
    'b-inquiry-preview__field-value--italic': italicValue,
  });

  return (
    <div className="row b-inquiry-preview__field">
      <div className="col-xs-4 b-inquiry-preview__field-name">
        {fieldName}
      </div>

      <div className={valueClassName}>
        <span className="qa-field-value">{fieldValue}</span>
        {tip
          && (
          <span style={{ marginLeft: 10 }}>
            <HelpTooltip text={tip} />
          </span>
          )}
      </div>
    </div>
  );
}

HorizontalField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.any,
  optional: PropTypes.bool,
  highlightValue: PropTypes.bool,
  italicValue: PropTypes.bool,
  tip: PropTypes.string,
};

HorizontalField.defaultProps = {
  fieldValue: '',
  optional: false,
  highlightValue: false,
  italicValue: false,
  tip: null,
};

module.exports = HorizontalField;
