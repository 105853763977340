const React = require('react');
const Layout = require('../layout');
const Form = require('./form');

class EditInvoice extends React.Component {
  render() {
    const {
      invoice,
    } = this.props;

    return (
      <Layout>
        <h1>Edit invoice</h1>
        <Form {...invoice} />
      </Layout>
    );
  }
}

module.exports = EditInvoice;
