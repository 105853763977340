const React = require('react');
const { saveInvoice, invoicesPath } = require('../../../resources/InvoiceResource');

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: { ...props },
    };
  }

  disabledFields() {
    const { invoice } = this.state;
    const {
      is_external, id, sent, cancelled,
    } = invoice;
    const is_new = !id;

    return {
      number_prefix: is_new || sent || cancelled,
      number_sequence: is_new || sent || cancelled,
      offer_id: !is_new || is_external || sent || cancelled,
      is_external: !is_new || sent,
      sent: false,
      cancelled: false,
    };
  }

  onError = ({ responseText }) => {
    alert(responseText);
  }

  onSuccess = () => {
    window.location = invoicesPath('index');
  }

  onChangeInput = (name, value) => {
    const { invoice } = this.state;

    this.setState({ invoice: { ...invoice, [name]: value } });
  }

  onSubmit = () => {
    const { invoice } = this.state;

    saveInvoice(invoice.id, { invoice }).then(this.onSuccess, this.onError);
  }

  onChangeCheckbox = (event) => {
    const { invoice } = this.state;
    const attr = event.target.id;

    this.setState({ invoice: { ...invoice, [attr]: event.target.checked } });
  }

  renderFormCheckbox(name, label) {
    const { invoice } = this.state;
    const value = invoice[name];

    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            id={name}
            checked={value}
            onChange={this.onChangeCheckbox}
            disabled={this.disabledFields()[name]}
          />
          <strong>{label}</strong>
        </label>
      </div>
    );
  }

  renderFormInput(name) {
    return (
      <div className="form-group">
        <label>{name}</label>
        <input
          name={name}
          type="text"
          className="form-control"
          onChange={(e) => this.onChangeInput(name, e.target.value)}
          value={this.state.invoice[name] || ''}
          disabled={this.disabledFields()[name]}
        />
      </div>
    );
  }

  render() {
    const { invoice } = this.state;
    const { id } = invoice;

    return (
      <form>
        {this.renderFormInput('number_prefix')}
        {this.renderFormInput('number_sequence')}
        {this.renderFormInput('offer_id')}
        {this.renderFormCheckbox('is_external', 'External invoice')}
        {this.renderFormCheckbox('sent', 'Sent')}
        {this.renderFormCheckbox('cancelled', 'Cancelled')}
        <button
          type="button"
          className="btn btn-default"
          onClick={this.onSubmit}
        >
          { id ? 'Save' : 'Create' }
        </button>
      </form>
    );
  }
}

module.exports = Form;
