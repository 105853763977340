const React = require('react');
const PropTypes = require('prop-types');
const BookingTotalsField = require('../booking_totals_field');
const { formatCurrency } = require('../../../lib/currency');

function FullTotals({ offer, skip_vat }) {
  const {
    total_vat_amount,
    vat_percent_basa,
    total_gross_booking_value_with_extra_costs,
    total_net_booking_value_with_extra_costs,
    currency,
  } = offer;

  const totalBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    skip_vat
      ? total_net_booking_value_with_extra_costs
      : total_gross_booking_value_with_extra_costs,
  );
  const totalVatAmount = formatCurrency(
    I18n.locale,
    currency,
    skip_vat ? 0 : total_vat_amount,
  );

  return (
    <>
      <BookingTotalsField
        className="value-dark name-dark"
        fieldName={I18n.t('offer.amount_due')}
        fieldValue={totalBookingValueWithExtraCosts}
      />
      <BookingTotalsField
        className="value-light qa-booking-totals-vat"
        fieldName={I18n.t('offer.vat', {
          vat: skip_vat ? 0 : vat_percent_basa,
        })}
        fieldValue={totalVatAmount}
        smallHint={I18n.t('offer.reverse_deduction_hint')}
      />
    </>
  );
}

FullTotals.propTypes = {
  offer: PropTypes.object.isRequired,
  skip_vat: PropTypes.bool,
};

FullTotals.defaultProps = {
  skip_vat: false,
};

module.exports = FullTotals;
