const React = require('react');
const PropTypes = require('prop-types');
const VimeoWidget = require('./vimeo_widget');
const YoutubeWidget = require('./youtube_widget');

class VideoSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddVideo = this.handleAddVideo.bind(this);
    this.getYoutubeId = this.getYoutubeId.bind(this);
    this.parseVideoUrls = this.parseVideoUrls.bind(this);
  }

  handleAddVideo(event) {
    event.preventDefault();
    let newVideoUrls;
    const newVideo = this.refs.videoUrlTextfield.value;
    if (this.getYoutubeId(newVideo) || this.getVimeoId(newVideo)) {
      if (!this.props.videoUrls) {
        newVideoUrls = newVideo;
      } else {
        newVideoUrls = `${this.props.videoUrls}|${newVideo}`;
      }
      this.props.onUpdate({ video_urls: newVideoUrls, errors: '' });
      this.refs.videoUrlTextfield.value = '';
    } else {
      this.props.onUpdate({ errors: I18n.t('artistic_services.errors.videos') });
    }
  }

  parseVideoUrls(urls) {
    return urls.split('|');
  }

  getYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    }
    return null;
  }

  getVimeoId(url) {
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url.match(regExp);
    if (match && match[3]) {
      return match[3];
    }
    return null;
  }

  getVideoWindowSizes(numberOfVideos) {
    if (numberOfVideos == 1) {
      return [12];
    } if (numberOfVideos == 2) {
      return [6, 6];
    } if (numberOfVideos == 3) {
      return [6, 6, 12];
    }
    return [6, 6, 6, 6];
  }

  handleRemoveVideo(index) {
    const urls = this.props.videoUrls.split('|');
    urls.splice(index, 1);
    this.props.onUpdate({ video_urls: urls.join('|') });
  }

  render() {
    const videoUrls = this.parseVideoUrls(this.props.videoUrls);
    const windowSizes = this.getVideoWindowSizes(videoUrls.length);
    const videoWidgets = videoUrls.map((videoUrl, index) => {
      const youtubeId = this.getYoutubeId(videoUrl);
      let widget = youtubeId ? <YoutubeWidget youtubeId={youtubeId} removeVideo={() => { this.handleRemoveVideo(index); }} show={this.props.show} /> : null;
      const vimeoId = this.getVimeoId(videoUrl);
      widget = vimeoId ? <VimeoWidget vimeoId={vimeoId} removeVideo={() => { this.handleRemoveVideo(index); }} show={this.props.show} /> : widget;
      return (
        <div key={index} className={`service-video col-sm-${windowSizes[index]}`}>
          {widget}
        </div>
      );
    });

    return (
      <div className="video-section">
        {!this.props.show && (
        <div><div className="col-sm-8 col-sm-offset-2">
          <p>{I18n.t('artistic_services.add_videos')}</p>
             </div>
          <form onSubmit={this.handleAddVideo} className={`form-inline${this.props.errors ? ' has-error' : ''}`}>
            <div className="col-sm-6 col-sm-offset-2">
              <input type="text" ref="videoUrlTextfield" className="form-control" />
            </div>
            <div className="col-sm-2">
              <button type="button" onClick={this.handleAddVideo} className="grey-round-button">{I18n.t('artistic_services.add')}</button>
            </div>
            {this.props.errors
              ? (
                <div className="col-sm-8 col-sm-offset-2">
                  <span className="error-block">{this.props.errors}</span>
                </div>
              ) : null}
          </form>
        </div>
        )}
        <div className={this.props.show ? '' : 'col-sm-8 col-sm-offset-2'}>
          {videoWidgets}
        </div>
      </div>
    );
  }
}

module.exports = VideoSection;
