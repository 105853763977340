/* global window */

// eslint-disable jsx-a11y/anchor-is-valid

/**
 * We disable this rule in this file because bootstrap dropdowns don't take
 * into account accesibility: they require an anchor element to be styled
 * properly, and we cannot put any sensible href value there.
 */

import React, { Component } from 'react';

import GoogleMapsApiLoader from 'google-maps-api-loader';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { Popper } from 'react-popper';

class LocationAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.autocompleteRef = React.createRef();
    this.dummyInputRef = React.createRef();

    this.state = {
      isLoading: false,
      placeId: null,
      isApiLoaded: Boolean(window.google && window.google.maps),
      value: props.value,
      loadGoogleMapsApiAttempt: false,
    };
  }

  onFocusDummy = () => {
    const { loadGoogleMapsApiAttempt } = this.state;

    if (!loadGoogleMapsApiAttempt) {
      this.loadGoogleMapsApi();
    }
  }

  onFocusAutocomplete = () => {
    this.setState({ placeId: null });
  }

  onChange = (value) => {
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  }

  onSelect = (value, placeId) => {
    const { onGeocode } = this.props;

    if (onGeocode && placeId) {
      geocodeByPlaceId(placeId).then(onGeocode);
    }

    this.setState({ value, placeId }, () => {
      this.props.onChange(value);
      this.props.onSelect(value);
    });
  }

  onBlur = (suggestions) => {
    this.setFallbackSuggestion(suggestions);
  }

  setFallbackSuggestion(suggestions) {
    if (this.state.placeId) {
      return;
    }

    const suggestion = suggestions.find(({ active }) => active) || suggestions[0];

    if (!suggestion) {
      return;
    }

    const { id, description } = suggestion;
    this.setState({ value: description, placeId: id });
  }

  loadGoogleMapsApi = () => {
    this.setState({ isLoading: true });

    GoogleMapsApiLoader({
      libraries: ['places'],
      apiKey: this.props.google_maps_api_key,
      language: I18n.locale,
    }).then(() => {
      this.setState({
        isLoading: false,
        isApiLoaded: true,
        loadGoogleMapsApiAttempt: true,
      }, () => {
        this.autocompleteRef.current.focus();
      });
    }, () => {
      this.setState({
        isLoading: false,
        loadGoogleMapsApiAttempt: true,
      }, () => {
        this.dummyInputRef.current.focus();
      });
    });
  }

  /**
   * Render a dummy input to trigger Google Maps API JS load on focus, saving
   * some network usage to our users <3
   */
  renderDummyInput() {
    return (
      <input
        autoComplete="off"
        className={this.props.inputClassName}
        onFocus={this.onFocusDummy}
        onChange={({ target }) => this.onChange(target.value)}
        value={this.state.value}
        placeholder={this.state.isLoading ? 'Loading ...' : this.props.placeholder}
        disabled={this.state.isLoading}
        name={this.props.fieldName}
        ref={this.dummyInputRef}
      />
    );
  }

  render() {
    if (!this.state.isApiLoaded) {
      return this.renderDummyInput();
    }

    const { searchOptionTypes } = this.props;

    return (
      <PlacesAutocomplete
        value={this.state.value}
        onChange={this.onChange}
        onSelect={this.onSelect}
        searchOptions={{ types: searchOptionTypes }}
        highlightFirstSuggestion
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
        }) => (
          <>
            <input
              ref={this.autocompleteRef}
              {...getInputProps({
                className: this.props.inputClassName,
                name: this.props.fieldName,
                placeholder: this.props.placeholder,
                autoComplete: 'off',
                onFocus: this.onFocusAutocomplete,
                onBlur: () => this.onBlur(suggestions),
              })}
            />
            { suggestions.length > 0 && (
              <Popper
                placement="bottom-start"
                modifiers={{
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                eventsEnabled
                referenceElement={this.autocompleteRef.current}
              >
                {({ ref, style, placement }) => (
                  <ul
                    data-placement={placement}
                    data-qa-locator="maps-suggestions"
                    className="b-location-autocomplete__dropdown-menu show"
                    ref={ref}
                    style={{ ...style, width: 400, overflow: 'hidden' }}
                  >
                    {(suggestions.slice(0, this.props.maxSuggestions).map((suggestion) => (
                      <li {...getSuggestionItemProps(suggestion, { className: suggestion.active ? 'active' : '' })}>
                        <a>{suggestion.description}</a>
                      </li>
                    )))}
                  </ul>
                )}
              </Popper>
            )}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationAutocomplete.defaultProps = {
  value: '',
  onChange: () => {},
  onSelect: () => {},
  onGeocode: null,
  inputClassName: undefined,
  searchOptionTypes: ['(cities)'],
  maxSuggestions: 5,
};

LocationAutocomplete.propTypes = {
  google_maps_api_key: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onGeocode: PropTypes.func,
  value: PropTypes.string,
  inputClassName: PropTypes.string,
  searchOptionTypes: PropTypes.arrayOf(PropTypes.string),
  maxSuggestions: PropTypes.number,
};

export default LocationAutocomplete;
