const React = require('react');
const PropTypes = require('prop-types');

const {
  CANCELLATION_POLICY_OPTIONS,
  CANCELLATION_POLICY_OPTIONS_WITH_CUSTOM,
} = require('../../domain/offer');

const policyContent = (option, pov, content) => {
  if (CANCELLATION_POLICY_OPTIONS.includes(option)) {
    return I18n.t(`cancellation_policies.${pov}.${option}.content`);
  }

  return content;
};

const CancellationPolicy = ({
  content,
  option,
  pov,
  showNotes,
  amountDue,
}) => {
  const head = I18n.t(`cancellation_policies.${pov}.head`);
  const note_0 = I18n.t('cancellation_policies.client.note_0');
  const note_1 = I18n.t(`cancellation_policies.${pov}.note_1`);
  const note_2 = I18n.t('cancellation_policies.client.note_2', { amount_due: amountDue });

  // eslint-disable-next-line react/no-danger
  return (
    <div className="b-cancellation-field__content" id="foobar">
      <p>{head}</p>
      <p dangerouslySetInnerHTML={{ __html: policyContent(option, pov, content) }} />
      {showNotes && pov === 'artist' && (
        <p>{note_1}</p>
      )}
      {showNotes && pov === 'client' && (
        <>
          <p>{note_0}</p>
          <p>- {note_1}<br />- {note_2}</p>
        </>
      )}
    </div>
  );
};

CancellationPolicy.propTypes = {
  content: PropTypes.string.isRequired,
  option: PropTypes.oneOf(CANCELLATION_POLICY_OPTIONS_WITH_CUSTOM).isRequired,
  pov: PropTypes.oneOf(['client', 'artist']).isRequired,
  showNotes: PropTypes.bool,
  amountDue: PropTypes.string.isRequired,
};

CancellationPolicy.defaultProps = {
  showNotes: true,
};

module.exports = CancellationPolicy;
