const React = require('react');
const PropTypes = require('prop-types');
const { bookingConfirmationsPath } = require('../../../resources/BookingConfirmationResource');
const { formatDate } = require('../../../lib/datetime_formatters');

const renderDate = (value) => {
  return value ? formatDate(value, 'yyyy-MM-dd HH:mm') : '-';
};

class InvoicesTable extends React.Component {
  renderHeader() {
    return (
      <tr>
        <th>Booking number</th>
        <th>Sent to Client</th>
        <th>Sent to Artist</th>
        <th className="b-admin__actions">Actions</th>
      </tr>
    );
  }

  renderPdfActions(booking_confirmation_id) {
    return (
      <span>
        <a href={`${bookingConfirmationsPath('show', { id: booking_confirmation_id })}/pdf_show_or_create`} target="_blank" rel="noreferrer">PDF</a>
        <a href={`${bookingConfirmationsPath('show', { id: booking_confirmation_id })}/pdf_recreate`} target="_blank" rel="noreferrer">Recreate</a>
      </span>
    );
  }

  renderRow() {
    const {
      booking_confirmation_id,
      booking_number,
      booking_confirmation,
    } = this.props;

    return (
      <tr key={booking_confirmation_id}>
        <td>{booking_number}</td>
        <td className="offer-booking-confirmation__sent-to-client-at">
          {booking_confirmation ? renderDate(booking_confirmation.sent_to_client_at) : ''}
        </td>
        <td className="offer-booking-confirmation__sent-to-artist-at">
          {booking_confirmation ? renderDate(booking_confirmation.sent_to_artist_at) : ''}
        </td>
        <td className="b-admin__actions">
          {this.renderPdfActions(booking_confirmation_id)}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <table className="table table-striped">
        <thead>{this.renderHeader()}</thead>
        <tbody>{this.renderRow()}</tbody>
      </table>
    );
  }
}

InvoicesTable.propTypes = {
  booking_confirmation_id: PropTypes.number,
  booking_confirmation: PropTypes.object,
  booking_number: PropTypes.string.isRequired,
};

InvoicesTable.defaultProps = {
  booking_confirmation_id: null,
  booking_confirmation: null,
};

module.exports = InvoicesTable;
