const React = require('react');
const Layout = require('../layout');
const InvitationRequest = require('./InvitationRequest');
const AdminResponseForm = require('./AdminResponseForm');

const Review = (props) => {
  const {
    invitation_request,
    admin_responses_yes,
    admin_responses_no,
    admin_responses_incomplete,
  } = props;

  const { id } = invitation_request;
  const haveResponse = invitation_request.admin_response_at || invitation_request.approved_at;

  return (
    <Layout>
      <h1>
        <a href="/admin/invitation_requests"><span className="glyphicon glyphicon-arrow-left" /></a>
        <span style={{ marginLeft: 16 }}>{`Review Invitation Request #${invitation_request.id}`}</span>
      </h1>
      <InvitationRequest {...invitation_request} />
      {!haveResponse
      && (
      <AdminResponseForm
        invitation_request_id={id}
        admin_responses_yes={admin_responses_yes}
        admin_responses_no={admin_responses_no}
        admin_responses_incomplete={admin_responses_incomplete}
      />
      )}
    </Layout>
  );
};

module.exports = Review;
