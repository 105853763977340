const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const CheckIcon = require('../Icons/CheckIcon').default;
const ErrorIcon = require('../Icons/ErrorIcon').default;

function TextArea({
  name,
  value,
  placeholder,
  onChange,
  maxLength,
  error,
  validated,
  label,
  expanded,
}) {
  const hasError = !!error;
  const hasValue = !!value;

  return (
    <div className={classNames('b-input', { 'b-input--error': hasError })}>
      <div className="b-input__top">
        <div className="b-input__label">{label}</div>
        {hasError && <div className="b-input__error">{error}</div>}
      </div>
      <div className="b-input__input-wrapper">
        <textarea
          className={
            classNames(
              'b-input__textarea b-input__input',
              {
                'b-input__input--empty': !hasValue,
                'b-input__input--expanded': expanded,
              },
            )
          }
          name={name}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          maxLength={maxLength}
        />
        {hasError && <div className="b-input__icon"><ErrorIcon /></div>}
        {!hasError && validated && <div className="b-input__icon"><CheckIcon /></div>}
      </div>
    </div>
  );
}

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  label: PropTypes.string,
  validated: PropTypes.bool,
  error: PropTypes.string,
  expanded: PropTypes.bool,
};

TextArea.defaultProps = {
  label: undefined,
  validated: false,
  error: undefined,
  expanded: false,
  placeholder: '',
};

module.exports = TextArea;
