const React = require('react');
const PropTypes = require('prop-types');
const ReactSelect = require('react-select').default;

// eslint-disable-next-line react/prop-types
const OptionLabel = ({ value, label }) => (
  <span className={`react-select-option-value-${value}`}>{label}</span>
);

const mapOptions = (options) => (
  options.map(({ value, label }) => (
    { value, label: <OptionLabel value={value} label={label} /> }
  ))
);

const Select = (props) => {
  const { reactModal, options } = props;
  const placeholder = window.I18n ? I18n.t('smart_form.shared.select_one') : undefined;
  const menuPortalTarget = reactModal ? $('.b-react-modal__overlay')[0] : undefined;
  const styles = reactModal ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : undefined;

  return (
    <ReactSelect
      className="b-select"
      classNamePrefix="react-select"
      isClearable={false}
      isSearchable={false}
      isRtl={false}
      placeholder={placeholder}
      {...props}
      options={mapOptions(options)}
      menuPortalTarget={menuPortalTarget}
      styles={styles}
    />
  );
};

Select.defaultProps = {
  reactModal: false,
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  reactModal: PropTypes.bool,
};

module.exports = Select;
