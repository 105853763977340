import React from 'react';
import PropTypes from 'prop-types';

import Step from '../step';
import InquiryPreview from './inquiry_preview';
import Heading from '../../ui/Heading';

const Canceled = ({
  inquiry,
  forwardedInquiryArtisticService,
  forwardedInquiry,
  artisticService,
  visitorType,
}) => (
  <div className="b-negotiation">
    <Heading text={I18n.t('booking_cancelation.inquiry_was_closed')} />
    <hr />
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Step
        title={I18n.t(`negotiation_dashboard.${visitorType}.title`)}
        folded={false}
        blocked
      >
        <InquiryPreview
          inquiry={inquiry}
          artisticService={forwardedInquiryArtisticService || artisticService}
          forwardedInquiry={forwardedInquiry}
        />
      </Step>
    </div>
  </div>
);

Canceled.propTypes = {
  inquiry: PropTypes.object.isRequired,
  forwardedInquiry: PropTypes.object.isRequired,
  artisticService: PropTypes.object.isRequired,
  forwardedInquiryArtisticService: PropTypes.object.isRequired,
  visitorType: PropTypes.string.isRequired,
};

export default Canceled;
