const React = require('react');
const PricingField = require('./PricingField');
const ExtraCostField = require('./ExtraCostField');
const withArtistDashboardContext = require('../with-artist-dashboard-context');
const ContextPropTypes = require('../context-prop-types');
const { formatCurrency } = require('../../../../../lib/currency');

const Details = ({ context }) => {
  const { draftOffer, computedPricing, extraCosts } = context;
  const { currency } = draftOffer;

  const netAmountArtist = formatCurrency(
    I18n.locale,
    currency,
    draftOffer.net_amount_artist || 0,
  );
  const vatAmountArtist = formatCurrency(
    I18n.locale,
    currency,
    computedPricing.vat_amount_artist,
  );
  const netAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    computedPricing.net_amount_basa,
  );
  const vatAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    computedPricing.vat_amount_basa,
  );
  const totalGrossBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    computedPricing.total_gross_booking_value_with_extra_costs,
  );
  const grossAmountArtistWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    computedPricing.gross_amount_artist_with_extra_costs,
  );

  return (
    <>
      <PricingField
        name="net_amount_artist"
        label={I18n.t('negotiation_dashboard.artist.your_net_value')}
        value={netAmountArtist}
      />
      <PricingField
        name="vat_amount_artist"
        label={I18n.t('negotiation_dashboard.artist.your_vat')}
        value={vatAmountArtist}
      />
      <PricingField
        name="net_amount_basa"
        label={I18n.t('negotiation_dashboard.artist.our_net_value')}
        value={netAmountBasa}
      />
      <PricingField
        name="vat_amount_basa"
        label={I18n.t('negotiation_dashboard.artist.our_vat')}
        value={vatAmountBasa}
      />

      {!!extraCosts.length && (
        <>
          <div className="row">
            <div className="col-xs-7 col-xs-offset-1">
              <div
                className="b-artist-pricing__hint-field"
                style={{ fontSize: 12, color: 'black' }}
              >
                {I18n.t('negotiation_dashboard.artist.extra_costs')}
              </div>
            </div>
          </div>
          {extraCosts.map(({ id, name, value }) => {
            const formattedValue = formatCurrency(
              I18n.locale,
              currency,
              value || 0,
            );
            return (
              <ExtraCostField
                key={id}
                name={name || '-'}
                value={formattedValue}
              />
            );
          })}
        </>
      )}
      <div className="row b-artist-pricing__line-break" />
      <PricingField
        name="total_gross_booking_value_with_extra_costs"
        label={I18n.t('negotiation_dashboard.artist.total_gross_client')}
        value={totalGrossBookingValueWithExtraCosts}
        highlight
      />
      <PricingField
        name="gross_amount_artist_with_extra_costs"
        label={I18n.t('negotiation_dashboard.artist.total_amount_you_receive')}
        value={grossAmountArtistWithExtraCosts}
      />
    </>
  );
};

Details.propTypes = {
  context: ContextPropTypes.isRequired,
};

module.exports = withArtistDashboardContext(Details);
