const React = require('react');
const PropTypes = require('prop-types');

const SPACING_UNIT = 10;

const List = ({ children, spacing = 2 }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: -(spacing * SPACING_UNIT) / 2,
      marginBottom: -(spacing * SPACING_UNIT) / 2,
      justifyContent: 'center',
    }}
  >
    {React.Children.map(children, (child) => (
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginTop: (spacing * SPACING_UNIT) / 2,
          marginBottom: (spacing * SPACING_UNIT) / 2,
          justifyContent: 'center',
        }}
      >
        {child}
      </div>
    ))}
  </div>
);

List.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  spacing: PropTypes.number,
};

List.defaultProps = {
  spacing: 1,
};

module.exports = List;
