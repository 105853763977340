const React = require('react');

const parseLink = (link) => {
  if (link.match(/:\/\//)) {
    return link;
  }

  return `//${link}`;
};

const PortfolioLinks = (props) => props.links.map((link) => (
  <a
    href={parseLink(link)}
    style={{ marginRight: 10 }}
    key={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    {link}
  </a>
));

module.exports = PortfolioLinks;
