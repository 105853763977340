const React = require('react');

class AudioSection extends React.Component {
  constructor(props) {
    super(props);

    this.parseAudioUrls = this.parseAudioUrls.bind(this);
  }

  getSoundcloudUrl(url) {
    return url.includes('soundcloud') ? url : null;
  }

  parseAudioUrls(urls) {
    return urls && urls.split('|') || [];
  }

  renderSoundCloudWidget(soundcloudUrl) {
    return (
      <div key={soundcloudUrl} className="embed-responsive b-service__audio-section__iframe-wrapper">
        <iframe
          className="embed-responsive-item"
          style={{ height: 135 }}
          scrolling="no"
          frameBorder="no"
          src={`https://w.soundcloud.com/player/?url=${soundcloudUrl}&amp;color=ff6600&amp;auto_play=false&amp;show_artwork=true`}
        />
      </div>
    );
  }

  renderAudioWidgets() {
    const audioUrls = this.parseAudioUrls(this.props.audioUrls);

    return audioUrls.map((audioUrl) => {
      const soundcloudUrl = this.getSoundcloudUrl(audioUrl);

      if (!soundcloudUrl) return null;

      return this.renderSoundCloudWidget(soundcloudUrl);
    });
  }

  render() {
    return (
      <div className="b-service__audio-section">
        {this.renderAudioWidgets()}
      </div>
    );
  }
}

module.exports = AudioSection;
