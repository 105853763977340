const React = require('react');
const PropTypes = require('prop-types');

const ScrollableAnchor = require('react-scrollable-anchor').default;
const NegotiationChat = require('../../negotiation_chat/negotiation-chat');
const InquiryPreview = require('./inquiry_preview');
const ArtistOfferView = require('../artist_offer_view');
const Step = require('../step');
const BookingCancelation = require('./cancelation').default;
const HighDemand = require('../step/high_demand');
const { isPaymentConfirmed } = require('../../../domain/payment');

class ClientDashboard extends React.Component {
  constructor(props) {
    super(props);

    const hasMessages = !!props.messages.length;

    this.state = {
      chatFolded: false,
      offerFolded: !hasMessages && !props.offer,
      inquiryFolded: hasMessages,
    };

    this.toggleInquiryFolded = this.toggleInquiryFolded.bind(this);
    this.toggleChatFolded = this.toggleChatFolded.bind(this);
    this.toggleOfferFolded = this.toggleOfferFolded.bind(this);
  }

  toggleInquiryFolded() {
    const { inquiryFolded } = this.state;

    this.setState({ inquiryFolded: !inquiryFolded });
  }

  toggleChatFolded() {
    const { chatFolded } = this.state;

    this.setState({ chatFolded: !chatFolded });
  }

  toggleOfferFolded() {
    const { offerFolded } = this.state;

    this.setState({ offerFolded: !offerFolded });
  }

  secondStepTitle() {
    const { artistic_profile_path, forwardedInquiry } = this.props;

    if (artistic_profile_path) {
      return I18n.t('negotiation_dashboard.client.get_in_touch_html', { name: forwardedInquiry.artist_name, link: artistic_profile_path });
    }

    return I18n.t('negotiation_dashboard.client.get_in_touch', { name: forwardedInquiry.artist_name });
  }

  renderOffer() {
    const {
      paymentPath,
      offer,
      extraCosts,
    } = this.props;

    if (!offer) return null;

    return (
      <div className="b-artist-dashboard__artist-offer-view">
        <ArtistOfferView
          offer={offer}
          extraCosts={extraCosts}
          pov="client"
        />
        <div className="b-client-dashboard__artist-offer-pay">
          <a className="b-button" href={paymentPath}>
            {I18n.t('negotiation_dashboard.client.pay', { amount: offer.gross_amount_basa })}
          </a>
        </div>
      </div>
    );
  }

  renderOfferStep() {
    const { offer, faq_path } = this.props;

    const title = offer
      ? I18n.t('negotiation_dashboard.client.accept_offer')
      : I18n.t('negotiation_dashboard.client.offer_final_price');

    const isConfirmed = offer ? isPaymentConfirmed(offer) : false;

    return (
      <Step
        title={title}
        number={3}
        folded={!offer}
        tips={offer ? ['client.pay_to_confirm'] : []}
        faqPath={faq_path}
        done={isConfirmed}
        blocked
      >
        {this.renderOffer(isConfirmed)}
      </Step>
    );
  }

  render() {
    const {
      inquiry,
      forwardedInquiry,
      messages,
      messagingPath,
      faq_path,
      artistic_profile_path,
      artisticService,
      cancelationReasons,
      cancelationPath,
      isHighDemand,
      forwardedInquiryArtisticService,
    } = this.props;

    return (
      <div id="client-negotiation-dashboard" className="b-negotiation">
        {isHighDemand && (
          <HighDemand />
        )}
        <Step
          title={I18n.t('negotiation_dashboard.client.title')}
          number={1}
          folded={this.state.inquiryFolded}
          onClickHeader={this.toggleInquiryFolded}
          foldingLocator="qa-toggle-inquiry"
          tips={['client.request_copy']}
          faqPath={faq_path}
          done
        >
          <InquiryPreview
            inquiry={inquiry}
            forwardedInquiry={forwardedInquiry}
            artisticService={forwardedInquiryArtisticService || artisticService}
          />
          <div className="b-negotiation__button-wrapper">
            <div>
              <BookingCancelation
                authorType="client"
                reasons={cancelationReasons}
                cancelationPath={cancelationPath}
              />
            </div>
          </div>
        </Step>

        <ScrollableAnchor id="chat"><div /></ScrollableAnchor>
        <Step
          title={this.secondStepTitle()}
          number={2}
          folded={this.state.chatFolded}
          onClickHeader={this.toggleChatFolded}
          tips={['client.many_requests', 'client.more_information', 'client.attach_stuff']}
          faqPath={faq_path}
          dangerousTitle={!!artistic_profile_path}
          done
        >
          <p className="b-negotiation__description">
            {I18n.t('negotiation_dashboard.client.messenger_description')}
          </p>
          <NegotiationChat
            origin="client"
            userName={forwardedInquiry.artist_name}
            messages={messages}
            messagingPath={messagingPath}
            placeholder={I18n.t('negotiation_dashboard.client.messenger_placeholder')}
          />
        </Step>

        {this.renderOfferStep()}
      </div>
    );
  }
}

ClientDashboard.propTypes = {
  inquiry: PropTypes.object.isRequired,
  offer: PropTypes.object,
  extraCosts: PropTypes.array.isRequired,
  forwardedInquiry: PropTypes.object.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messagingPath: PropTypes.string.isRequired,
  paymentPath: PropTypes.string,
  cancelationPath: PropTypes.string.isRequired,
  faq_path: PropTypes.string.isRequired,
  artistic_profile_path: PropTypes.string,
  artisticService: PropTypes.object,
  cancelationReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  forwardedInquiryArtisticService: PropTypes.object,
  isHighDemand: PropTypes.bool.isRequired,
};

ClientDashboard.defaultProps = {
  artisticService: null,
  artistic_profile_path: null,
  forwardedInquiryArtisticService: null,
  offer: null,
  paymentPath: '',
};

module.exports = ClientDashboard;
