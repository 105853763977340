/* eslint-disable react/jsx-fragments */
/* global window */
const React = require('react');
const PropTypes = require('prop-types');
const debounce = require('lodash.debounce');
const { ampEvent } = require('lib/amplitude');

const InquiryStep = require('./inquiry-step');
const ChatStep = require('./chat-step');
const OfferStep = require('./offer-step');
const Tips = require('../../step/tips');
const {
  saveExtraCost,
  deleteExtraCost,
} = require('../../../../resources/ExtraCostResource');

const ArtistOfferSentModal = require('./artist_offer_sent_modal');
const ArtistOfferDoubleCheckModal = require('./artist_offer_double_check_modal');

const ArtistDashboardContext = require('./context');

const SAVE_DEBOUNCE_MS = 800;
const RECOMPUTE_DEBOUNCE_MS = 350;

const { sumValues } = require('../../../../lib/calculations');

// B test
const { dataLayer } = require('../../../../lib/analytics');

class ArtistDashboard extends React.Component {
  constructor(props) {
    super(props);

    // TODO these two are computed fields, maybe could be moved to policies
    const isOfferSent = Boolean(props.offer);
    const hasMessages = (props.messages || []).length > 0;
    const hasPricingInfo = Boolean(
      props.draftOffer.vat_percent_artist > 0
        || props.draftOffer.service_amount > 0,
    );

    this.state = {
      draftOffer: props.draftOffer,
      offer: props.offer,
      extraCosts: props.extraCosts,
      extraCostsErrors: {},
      isOfferSent,
      isSending: false,
      isSaving: false,
      errors: {},
      computedPricing: {},
      currentModal: null,
      showCalculator: false,
      messages: props.messages,
      isFoldedByStep: {
        inquiry: hasMessages,
        chat: hasPricingInfo ? false : !hasMessages || isOfferSent,
        offer: !hasMessages,
      },
      isEditingSentOffer: false,
    };

    this.debouncedFuncs = {};
    this.debouncedSaveDraft = debounce(this.saveDraft, SAVE_DEBOUNCE_MS);
    this.recomputePricing = debounce(
      this.recomputePricing,
      RECOMPUTE_DEBOUNCE_MS,
    );
  }

  componentDidMount() {
    this.recomputePricing();
    this.enhanceDraftOffer();

    const searchParams = new URLSearchParams(window.location.search);
    const openParam = searchParams.get('open');
    if (openParam === 'chat') {
      this.scrollToChat();
    }

    if (openParam === 'offer') {
      this.scrollToOffer();
    }
  }

  componentDidUpdate(prevProps = {}, prevState = {}) {
    this.maybeRedirectToNextStep(prevProps, prevState);
    this.maybeRecomputePricing(prevProps, prevState);
  }

  onDoubleCheckConfirm = () => {
    if (this.state.isEditingSentOffer) {
      this.updateOffer();
    } else {
      this.sendOffer();
    }
  };

  onModalClose = () => this.setState({ currentModal: null });

  getContextValue() {
    return {
      booking: this.props.booking,
      inquiry: this.props.inquiry,
      agreementAccepted: this.props.agreementAccepted,
      bookingPublicArtistId: this.props.bookingPublicArtistId,
      draftOffer: this.state.draftOffer,
      offer: this.state.offer,
      extraCosts: this.state.extraCosts,
      extraCostsErrors: this.state.extraCostsErrors,
      errors: this.state.errors,
      error: this.state.error,
      client: this.state.client,
      computedPricing: this.state.computedPricing,
      isOfferSent: this.state.isOfferSent,
      isFoldedByStep: this.state.isFoldedByStep,
      isSending: this.state.isSending,
      isSaving: this.state.isSaving,
      showCalculator: this.state.showCalculator,
      isMobile: this.props.isMobile,
      faqPath: this.props.faqPath,

      tipsGroup: this.props.tipsGroup,
      messages: this.state.messages,
      forwardedInquiry: this.props.forwardedInquiry,
      artisticService: this.props.artisticService,
      artisticProfileId: this.props.artisticProfileId,
      cancelationReasons: this.props.cancelationReasons,
      forwardedInquiryArtisticService:
        this.props.forwardedInquiryArtisticService,

      cancelationPath: this.props.cancelationPath,
      calculatePricingPath: this.props.calculatePricingPath,
      messagingPath: this.props.messagingPath,
      isEditingSentOffer: this.state.isEditingSentOffer,

      updateDraftOffer: this.updateDraftOffer,
      updateExtraCost: this.updateExtraCost,
      createExtraCost: this.createExtraCost,
      deleteExtraCost: this.deleteExtraCost,
      validateOffer: this.validateOffer,
      clearErrors: this.clearErrors,
      toggleFold: this.toggleFold,
      unfoldChat: this.unfoldChat,
      toggleCalculator: this.toggleCalculator,
      notReadyToSend: this.notReadyToSend,
      readyToSend: this.readyToSend,
      goToOffer: this.goToOffer,
      onSendChatMessage: this.onSendChatMessage,
      onOfferBack: this.onOfferBack,
      scrollToCalculator: this.scrollToCalculator,
      editSentOffer: this.editSentOffer,
    };
  }

  setErrors = ({ errors, extraCostsErrors }) => {
    if (errors.net_amount_artist_cents !== undefined) {
      Object.assign(errors, {
        zero_total_amount: I18n.t(
          'negotiation_dashboard.artist.net_amount_artist_cents',
        ),
      });
    }

    this.setState(
      {
        errors: errors || {},
        extraCostsErrors: extraCostsErrors || {},
        error: I18n.t('negotiation_dashboard.artist.please_fill_all'),
        isSending: false,
      },
      () => this.scrollToFirstError(errors),
    );
  };

  maybeRedirectToNextStep = (_prevProps, prevState) => {
    const { currentModal } = this.state;
    const shouldRedirect = prevState.currentModal === 'offer-sent' && currentModal === null;

    if (!shouldRedirect) {
      return;
    }

    window.location.assign(this.props.artistNextStepsPath);
  };

  maybeRecomputePricing = (_prevProps, prevState) => {
    const {
      draftOffer: { service_amount, vat_percent_artist },
    } = this.state;
    const shouldRecomputePricing = service_amount !== prevState.draftOffer.service_amount
      || vat_percent_artist !== prevState.draftOffer.vat_percent_artist;

    if (!shouldRecomputePricing) {
      return;
    }

    this.recomputePricing();
  };

  recomputePricing() {
    $.get(this.props.calculatePricingPath, {
      amount_extra_costs: sumValues(this.state.extraCosts),
      service_amount: this.state.draftOffer.service_amount,
      vat_percent_artist: this.state.draftOffer.vat_percent_artist,
    }).done((computedPricing) => {
      this.setState({ computedPricing }, () => {
        this.updateDraftOffer(computedPricing, false);
      });
    });
  }

  offerPayload() {
    return {
      offer: this.state.draftOffer,
    };
  }

  enhanceDraftOffer() {
    const { net_amount_artist_cents, net_amount_basa_cents } = this.state.draftOffer;
    this.setState({
      draftOffer: {
        ...this.state.draftOffer,
        service_amount: (net_amount_artist_cents + net_amount_basa_cents) / 100,
      },
    });
  }

  editSentOffer = () => {
    this.setState({
      isEditingSentOffer: true,
    });
    this.unfoldOffer();
  }

  scrollToCalculator = () => {
    const chatStepSelector = '.qa-toggle-chat';
    const calculator = document.querySelector(chatStepSelector);

    this.toggleFold('chat', false);

    if (!this.state.showCalculator) {
      this.toggleCalculator();
    }

    setTimeout(() => {
      calculator.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  };

  scrollToChat = () => {
    const chat = document.querySelector('#chat-section');

    this.unfoldChat();

    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top:
          chat.getBoundingClientRect().top
          - document.body.getBoundingClientRect().top
          - 60,
      });
    }, 100);
  };

  scrollToOffer = () => {
    const offer = document.querySelector('#offer-step');

    this.unfoldOffer();

    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top:
          offer.getBoundingClientRect().top
          - document.body.getBoundingClientRect().top
          - 60,
      });
    }, 100);
  };

  unfoldChat = () => this.toggleFold('chat', false);

  unfoldOffer = () => this.toggleFold('offer', false);

  scrollToFirstError(errors = {}) {
    const errorNames = Object.keys(errors).join(' ');
    const isChatError = errorNames.match('service_amount')
      || errorNames.match('vat_percent_artist')
      || errorNames.match('zero_total_amount');

    if (isChatError) {
      this.unfoldChat();
      this.scrollToCalculator();
      return;
    }

    setTimeout(() => {
      const $firstErrorElement = $(
        '.b-artist-pricing__input--error, .b-input-field__value--error',
      ).first();

      if (!$firstErrorElement.length) return;

      $('html, body').animate(
        { scrollTop: $firstErrorElement.offset().top - 100 },
        'slow',
      );
    });
  }

  validateExtraCosts = (callback) => {
    const { extraCosts, extraCostsErrors } = this.state;

    const isValid = !extraCosts.find(({ id, value, name }) => {
      if (!value) {
        extraCostsErrors[id] = {
          value: I18n.t('negotiation_dashboard.artist.cant_be_blank'),
        };
      }

      if (!name) {
        extraCostsErrors[id] = {
          name: I18n.t('negotiation_dashboard.artist.cant_be_blank'),
        };
      }

      return !value || !name;
    });

    if (isValid) {
      callback();
    } else {
      this.setErrors({ extraCostsErrors });
    }
  };

  validateOffer = () => {
    this.setState({
      isSending: true,
      showCalculator: true,
      errors: {},
    });

    this.validateExtraCosts(() => {
      this.saveDraft()
        .then(() => $.post(this.props.bookingOfferValidationPath))
        .done(() => this.setState({
          isSending: false,
          currentModal: 'double-check',
          error: '',
        }))
        .fail((data) => this.setErrors({ errors: data.responseJSON }));
    });
  };

  saveDraft = () => {
    this.setState({ isSaving: true });

    return $.ajax({
      url: this.props.bookingDraftOfferPath,
      type: 'PUT',
      data: this.offerPayload(),
    }).always(() => setTimeout(() => this.setState({ isSaving: false }), 1000));
  };

  sendOffer = () => {
    this.setState({
      isSending: true,
      showCalculator: true,
      errors: {},
    });

    return $.post(this.props.bookingOfferPath)
      .done(this.handleOfferCreationSuccess)
      .fail(this.handleOfferCreationFailure);
  };

  updateOffer = () => {
    this.setState({
      isSending: true,
      showCalculator: true,
      errors: {},
    });

    return $.ajax({
      url: this.props.bookingOfferPath,
      type: 'PUT',
    })
      .done(this.handleOfferCreationSuccess)
      .fail(this.handleOfferCreationFailure);
  }

  handleOfferCreationSuccess = (offer) => {
    const { booking, inquiry } = this.props;

    dataLayer.push({
      event: 'offerSent',
      offerId: offer.id,
    });
    ampEvent('artist_dashboard: offerSent', {
      booking_id: booking.id,
      inquiry_id: inquiry.id,
      offer_id: offer.id,
    });

    this.setState({
      currentModal: 'offer-sent',
      error: '',
      isSending: true,
    });
  }

  handleOfferCreationFailure = (data) => {
    this.setErrors({ errors: data.responseJSON });
  }

  createExtraCost = (extraCost) => {
    const { bookingPublicArtistId } = this.props;
    const { extraCosts, draftOffer } = this.state;

    this.setState({ isSaving: true });

    saveExtraCost(null, {
      public_id: bookingPublicArtistId,
      extra_cost: { ...extraCost, offer_id: draftOffer.id },
    }).then((responseExtraCost) => {
      extraCosts.push(responseExtraCost);

      this.setState({ isSaving: false, extraCosts }, () => this.recomputePricing());
    });
  };

  deleteExtraCost = (id) => {
    const { bookingPublicArtistId } = this.props;
    const { extraCosts } = this.state;

    this.setState({ isSaving: true });

    deleteExtraCost(id, { public_id: bookingPublicArtistId }).then(() => {
      const updatedExtraCosts = extraCosts.filter(
        (extraCost) => extraCost.id !== id,
      );

      this.setState(
        {
          isSaving: false,
          extraCosts: updatedExtraCosts,
        },
        () => this.recomputePricing(),
      );
    });
  };

  debouncedSaveExtraCost = (extraCost) => {
    const { bookingPublicArtistId } = this.props;
    const { draftOffer, extraCostsErrors } = this.state;

    if (typeof this.debouncedFuncs[extraCost.id] === 'function') {
      this.debouncedFuncs[extraCost.id].cancel();
    }

    this.setState({ isSaving: true });

    const debouncedSave = debounce(() => {
      saveExtraCost(extraCost.id, {
        public_id: bookingPublicArtistId,
        extra_cost: { ...extraCost, offer_id: draftOffer.id },
      })
        .then(() => {
          extraCostsErrors[extraCost.id] = {};

          this.setState({ extraCostsErrors, isSaving: false }, () => this.recomputePricing());
        })
        .fail(({ responseJSON }) => {
          extraCostsErrors[extraCost.id] = responseJSON;

          this.setState({ extraCostsErrors, isSaving: false });
        });
    }, SAVE_DEBOUNCE_MS);

    this.debouncedFuncs[extraCost.id] = debouncedSave;

    debouncedSave();
  };

  updateExtraCost = (updatedExtraCost) => {
    const { extraCosts } = this.state;

    const updatedExtraCosts = extraCosts.map((extraCost) => {
      if (extraCost.id === updatedExtraCost.id) {
        return updatedExtraCost;
      }

      return extraCost;
    });

    this.setState({ extraCosts: updatedExtraCosts }, () => {
      this.recomputePricing();
      this.debouncedSaveExtraCost(updatedExtraCost);
    });
  };

  debouncedOfferChange = debounce(() => {
    dataLayer.push({
      event: 'artistUpdatedOffer',
    });

    ampEvent('artist_dashboard: updateOffer');
  }, 2000);

  updateDraftOffer = (values, skip_saving = false) => {
    const draftOffer = {
      ...this.state.draftOffer,
      ...values,
      ...this.state.computedPricing,
    };

    this.debouncedOfferChange();

    if (skip_saving) {
      this.setState({ draftOffer });
    } else {
      this.setState({ draftOffer }, this.debouncedSaveDraft);
    }
  };

  clearErrors = (keys) => {
    const omittedErrors = Object.entries(this.state.errors)
      .filter(([key]) => !keys.includes(key))
      .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {});

    const keysOfErrors = Object.keys(omittedErrors);
    const error = keysOfErrors.length > 0 ? this.state.error : '';

    this.setState({
      errors: omittedErrors,
      error,
    });
  };

  toggleCalculator = () => {
    const { showCalculator } = this.state;
    this.setState({ showCalculator: !showCalculator });

    const { booking, inquiry } = this.props;

    // Send tag to Hotjar
    if (showCalculator === false) {
      hj('tagRecording', ['open_calculator_click']);
      dataLayer.push({
        event: 'showCalculator',
      });
      ampEvent('artist_dashboard: showCalculator', {
        booking_id: booking.id,
        inquiry_id: inquiry.id,
      });
    } else {
      dataLayer.push({
        event: 'hideCalculator',
      });
      ampEvent('artist_dashboard: hideCalculator', {
        booking_id: booking.id,
        inquiry_id: inquiry.id,
      });
    }
  };

  toggleFold = (step, value = !this.state.isFoldedByStep[step]) => {
    this.setState({
      isFoldedByStep: {
        ...this.state.isFoldedByStep,
        [step]: value,
      },
    });
  };

  renderSavingIndicator() {
    if (!this.state.isSaving) {
      return null;
    }

    return (
      <div className="b-saving-indicator animate-blink">
        <span>{I18n.t('negotiation_dashboard.artist.saving')}</span>
      </div>
    );
  }

  layout() {
    return (
      <div id="artist-negotiation-dashboard" className="b-negotiation">
        <ArtistDashboardContext.Provider value={this.getContextValue()}>
          <InquiryStep />
          <ChatStep />
          <OfferStep />

          <div className="hidden-md hidden-lg" style={{ margin: '0 12px' }}>
            <Tips tips={['any_doubts']} faqPath={this.props.faqPath} isMobile />
          </div>

          <ArtistOfferDoubleCheckModal
            isSending={this.state.isSending}
            isOpen={this.state.currentModal === 'double-check'}
            onConfirm={this.onDoubleCheckConfirm}
            onClose={this.onModalClose}
          />

          <ArtistOfferSentModal
            isOpen={this.state.currentModal === 'offer-sent'}
            onClose={this.onModalClose}
          />

          {this.renderSavingIndicator()}
        </ArtistDashboardContext.Provider>
      </div>
    );
  }

  notReadyToSend = () => {
    const { booking, inquiry } = this.props;

    // Send tag to Hotjar
    hj('tagRecording', ['alternative_offer_no_btn_click']);
    dataLayer.push({
      event: 'notReadyToSend',
    });
    ampEvent('artist_dashboard: notReadyToSend', {
      booking_id: booking.id,
      inquiry_id: inquiry.id,
    });

    this.setState({
      showOffer: false,
    });
  };

  readyToSend = () => {
    const { booking, inquiry } = this.props;

    // Send tag to Hotjar
    hj('tagRecording', ['alternative_offer_yes_btn_click']);
    dataLayer.push({
      event: 'readyToSend',
    });
    ampEvent('artist_dashboard: readyToSend', {
      booking_id: booking.id,
      inquiry_id: inquiry.id,
    });

    this.setState({
      showOffer: true,
    });
  };

  goToOffer = () => {
    const { artistNextStepsPath } = this.props;

    window.location.assign(artistNextStepsPath);
  };

  onSendMessageModalClose = () => {
    const { artistDashboardPath } = this.props;

    window.location.href = artistDashboardPath;
  };

  onSendChatMessage = (messages) => {
    const { artistDashboardPath } = this.props;

    if (artistDashboardPath) {
      this.setState({
        isSending: false,
        currentModal: 'send-message',
      });
    } else {
      this.setState({
        messages,
        showOffer: null,
      });
    }
  };

  onOfferBack = () => {
    this.setState(
      {
        showOffer: null,
      },
      () => {
        const headerElement = document.querySelector('#artist-dashboard-cta');
        const bodyRect = document.body.getBoundingClientRect();
        const offset = headerElement.getBoundingClientRect().top - bodyRect.top;
        $('html, body').animate(
          {
            scrollTop: offset - 100,
          },
          'slow',
        );
      },
    );

    dataLayer.push({
      event: 'onOfferBack',
    });
    ampEvent('artist_dashboard: onOfferBack');
  };

  render() {
    return this.layout();
  }
}

ArtistDashboard.propTypes = {
  booking: PropTypes.object.isRequired,
  bookingPublicArtistId: PropTypes.string.isRequired,
  offer: PropTypes.object,
  draftOffer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  inquiry: PropTypes.object.isRequired,
  forwardedInquiry: PropTypes.object.isRequired,
  artisticService: PropTypes.object,
  artisticProfileId: PropTypes.number,
  forwardedInquiryArtisticService: PropTypes.object,
  tipsGroup: PropTypes.object.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  bookingDraftOfferPath: PropTypes.string.isRequired,
  bookingOfferPath: PropTypes.string.isRequired,
  bookingOfferValidationPath: PropTypes.string.isRequired,
  artistNextStepsPath: PropTypes.string.isRequired,
  calculatePricingPath: PropTypes.string.isRequired,
  messagingPath: PropTypes.string.isRequired,
  cancelationPath: PropTypes.string.isRequired,
  cancelationReasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
  faqPath: PropTypes.string.isRequired,
  agreementAccepted: PropTypes.bool.isRequired,
};

ArtistDashboard.defaultProps = {
  offer: null,
  artisticService: null,
  artisticProfileId: null,
  forwardedInquiryArtisticService: null,
};

module.exports = ArtistDashboard;
