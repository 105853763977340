require('slick-carousel');
const { dataLayer } = require('../lib/analytics');

const initSliders = function () {
  initServicesSlider();
  initSplashSlider();
};

var initServicesSlider = function () {
  const slickElementsPresent = $('.artistic-services-slider').find('.slick-slide')[0];

  if (slickElementsPresent) {
    return null;
  }

  const cities_count = +$('.js-cities-slider').data('slides-count');

  $('.js-cities-slider').slick({
    mobileFirst: true,
    infinite: (cities_count >= 4),
    slidesToShow: 2,
    arrows: false,
    variableWidth: true,
    responsive: [{
      breakpoint: 560,
      settings: {
        arrows: false,
        slidesToShow: 3,
      },
    }, {
      breakpoint: 768,
      settings: {
        arrows: (cities_count >= 4),
        slidesToShow: 4,
      },
    }],
  }).show();

  $('.js-customer-reviews-slider').slick({
    arrows: true,
    slidesToShow: 1,
    responsive: [{
      breakpoint: 768,
      settings: {
        infinite: false,
        arrows: false,
        centerMode: true,
      },
    }],
  }).show();

  $('.services-slider').on('afterChange', (event, slick) => {
    dataLayer.push({
      event: 'sliderChange',
      sliderName: 'services-slider',
    });
  });

  $('.services-slider').slick({
    mobileFirst: true,
    variableWidth: true,
    slidesToShow: 3,
    arrows: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 4,
      },
    }],
  }).show();
};

var initSplashSlider = function () {
  $('.occasion-slider').on('afterChange', () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sliderChange',
      sliderName: 'occasion-slider',
    });
  });

  $('.occasion-slider').slick({
    mobileFirst: true,
    slidesToShow: 2,
    variableWidth: true,
    arrows: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 3,
      },
    }],
  }).show();

  $('.customers-slider').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          variableWidth: false,
          centerMode: true,
          centerPadding: '30px',
        },
      },
    ],
  }).show();

  $('.b-data-slider').each(function () {
    $(this).slick({
      slidesToShow: parseInt($(this).attr('data-slides-to-show-mobile')),
      variableWidth: true,
      mobileFirst: true,
      arrows: false,
      responsive: [{
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: parseInt($(this).attr('data-slides-to-show')),
        },
      }],
    }).show();
  });

  $('.landing-categories-slider').slick({
    dots: true,
    mobileFirst: true,
  }).show();
};

const destructSliders = function () {
  $('.services-slider').slick('unslick');
  $('.occasion-slider').slick('unslick');
  $('.customers-slider').slick('unslick');
  $('.b-data-slider').slick('unslick');
  $('.js-cities-slider').slick('unslick');
  $('.js-customer-reviews-slider').slick('unslick');
  $('.landing-categories-slider').slick('unslick');
};

document.addEventListener('turbolinks:load', initSliders);
document.addEventListener('turbolinks:before-cache', destructSliders);
