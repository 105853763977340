const React = require('react');

class Pagination extends React.Component {
  pageLink(page) {
    const { rootPath } = this.props;

    return `${rootPath}?page=${page}`;
  }

  renderPreviousPageLink() {
    const { currentPage } = this.props;

    const is_disabled = currentPage === 1;
    const link = is_disabled ? '#' : this.pageLink(currentPage - 1);

    return (
      <li className={is_disabled ? 'disabled' : undefined}>
        <a href={link} aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
    );
  }

  renderNextPageLink() {
    const {
      currentPage,
      pagesCount,
    } = this.props;

    const is_disabled = currentPage === pagesCount;
    const link = is_disabled ? '#' : this.pageLink(currentPage + 1);

    return (
      <li className={is_disabled ? 'disabled' : undefined}>
        <a href={link} aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    );
  }

  renderPageItems() {
    const {
      pagesCount,
      currentPage,
    } = this.props;

    return Array.from({ length: pagesCount }).map((_, index) => {
      const page_number = index + 1;

      return (
        <li key={page_number} className={currentPage === page_number ? 'active' : undefined}>
          <a href={this.pageLink(page_number)}>{page_number}</a>
        </li>
      );
    });
  }

  render() {
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          {this.renderPreviousPageLink()}
          {this.renderPageItems()}
          {this.renderNextPageLink()}
        </ul>
      </nav>
    );
  }
}

module.exports = Pagination;
