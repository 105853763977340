const React = require('react');
const PropTypes = require('prop-types');
const flow = require('lodash.flow');

const Step = require('../../step');
const InquiryPreview = require('../inquiry_preview');
const withFoldableStep = require('./with-foldable-step');
const withArtistDashboardContext = require('./with-artist-dashboard-context');
const ContextPropTypes = require('./context-prop-types');
const BookingCancelation = require('../cancelation').default;
const HowToVideoButton = require('./HowToVideoButton');
const QuickContractModal = require('./QuickContractModal');

class InquiryStep extends React.Component {
  renderSidebar = () => {
    const {
      context: {
        artisticProfileId,
        tipsGroup,
        bookingPublicArtistId,
        agreementAccepted,
      },
    } = this.props;
    return (
      <>
        <QuickContractModal
          agreementAccepted={agreementAccepted}
          artisticProfileId={artisticProfileId}
          bookingPublicArtistId={bookingPublicArtistId}
        />
        <HowToVideoButton
          bookingPublicArtistId={bookingPublicArtistId}
          tipsGroup={tipsGroup}
          startOpened={false}
        />
      </>
    );
  };

  renderContactClientButton() {
    return (
      <a
        href="#chat"
        className="b-button"
        onClick={this.props.context.unfoldChat}
      >
        {I18n.t('negotiation_dashboard.artist.contact_the_client')}
      </a>
    );
  }

  render() {
    const {
      isFolded,
      onClickFold,
    } = this.props;

    const {
      inquiry,
      forwardedInquiry,
      artisticService,
      faqPath,
      cancelationReasons,
      cancelationPath,
      forwardedInquiryArtisticService,
    } = this.props.context;

    const hasMessages = (this.props.context.messages || []).length > 0;

    return (
      <Step
        title={I18n.t('negotiation_dashboard.artist.title')}
        number={1}
        done
        folded={isFolded}
        onClickHeader={onClickFold}
        foldingLocator="qa-toggle-inquiry"
        tips={['any_doubts']}
        faqPath={faqPath}
        renderSidebar={this.renderSidebar}
      >
        <InquiryPreview
          inquiry={inquiry}
          artisticService={forwardedInquiryArtisticService || artisticService}
          forwardedInquiry={forwardedInquiry}
          isArtist
        />
        <div className="b-negotiation__button-wrapper">
          <div>
            <BookingCancelation
              authorType="artist"
              reasons={cancelationReasons}
              cancelationPath={cancelationPath}
            />
          </div>
          <div>{!hasMessages && this.renderContactClientButton()}</div>
        </div>
      </Step>
    );
  }
}

InquiryStep.propTypes = {
  isFolded: PropTypes.bool.isRequired,
  onClickFold: PropTypes.func.isRequired,
  context: ContextPropTypes.isRequired,
};

module.exports = flow(
  withFoldableStep('inquiry'),
  withArtistDashboardContext,
)(InquiryStep);
