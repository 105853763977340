const React = require('react');
const PropTypes = require('prop-types');
const ReactSticky = require('react-sticky');
const classNames = require('classnames');

const BookingTotalsField = require('../booking_totals_field');
const ClientInfo = require('./client_info');
const BillingForm = require('./billing_form');
const FAQFooter = require('../faq_footer');
const AgreementCheckboxes = require('./agreement_checkboxes');
const Spinner = require('../../icons/spinner');

const BillingDataContext = require('./context');

const { saveClient } = require('../../../resources/ClientResource');
const { saveBillingDetail } = require('../../../resources/BillingDetailResource');
const { saveClientOffer } = require('../../../resources/ClientOfferResource');
const { scrollToDOMElement } = require('../../../lib/scroll_helpers');

const FullTotals = require('../booking_data/full_totals');
const PartialTotals = require('../booking_data/partial_totals');

class BillingData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {
        form: {},
        billing_detail: {},
        client: {},
      },
      form: {
        vatValidating: false,
        vatExclude: false,
        dataCollectAgree: false,
        tosAgree: false,
        isLoading: false,
        clientFormFolded: true,
      },
      billing_detail: props.billing_detail,
      client: props.client,
      offer: props.offer,
    };

    this.formRef = React.createRef();
    this.updateFormState = this.updateFormState.bind(this);
    this.updateBillingDetailState = this.updateBillingDetailState.bind(this);
    this.updateClientState = this.updateClientState.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onPersistBillingDetailError = this.onPersistBillingDetailError.bind(this);
    this.onPersistBillingDetailSuccess = this.onPersistBillingDetailSuccess.bind(this);
    this.onPersistClientError = this.onPersistClientError.bind(this);
    this.onPersistClientSuccess = this.onPersistClientSuccess.bind(this);
  }

  onClickNext() {
    $.when(
      this.persistClient()
        .done(this.onPersistClientSuccess)
        .fail(this.onPersistClientError),
      this.persistBillingDetails()
        .done(this.onPersistBillingDetailSuccess)
        .fail(this.onPersistBillingDetailError),
      this.persistDataCollectAgreement(),
    ).then(this.onSuccess, this.onError);
  }

  onSuccess() {
    const { billing_detail, client } = this.state;

    this.persistClientOffer()
      .done((offer) => this.props.onNext(billing_detail, client, offer))
      .fail(this.onError);
  }

  onError() {
    scrollToDOMElement(this.formRef.current);
  }

  onPersistBillingDetailSuccess(responseJSON) {
    const { errors } = this.state;

    errors.billing_detail = {};

    this.setState({
      billing_detail: responseJSON,
      errors,
    });
  }

  onPersistBillingDetailError(xhr) {
    const { responseJSON } = xhr;
    const { errors } = this.state;

    errors.billing_detail = responseJSON;
    this.setState({ errors });
  }

  onPersistClientSuccess(responseJSON) {
    const { errors } = this.state;

    errors.client = {};

    this.setState({
      client: responseJSON,
      errors,
    });
  }

  onPersistClientError(xhr) {
    const { responseJSON } = xhr;
    const { errors } = this.state;

    errors.client = responseJSON;
    this.setState({ errors });
    this.updateFormState({ clientFormFolded: false });
  }

  getContextValue() {
    const {
      form,
      billing_detail,
      client,
      errors,
      offer,
    } = this.state;

    return {
      updateFormState: this.updateFormState,
      updateBillingDetailState: this.updateBillingDetailState,
      updateClientState: this.updateClientState,
      form,
      billing_detail,
      client,
      offer,
      errors,
    };
  }

  updateOfferGrossAmountBasa(vatExclude) {
    const { offer } = this.state;
    const { net_amount_basa, vat_amount_basa } = offer;

    const gross_amount_basa = +net_amount_basa + (!vatExclude * +vat_amount_basa);

    this.setState({ offer: { ...offer, gross_amount_basa: String(gross_amount_basa) } });
  }

  updateFormState(values) {
    const { form } = this.state;
    const new_form = { ...form, ...values };

    this.setState({ form: new_form }, () => {
      if (form.vatExclude !== values.vatExclude) {
        this.updateOfferGrossAmountBasa(values.vatExclude);
      }
    });
  }

  updateBillingDetailState(values) {
    const { billing_detail } = this.state;
    const new_billing_detail = { ...billing_detail, ...values };

    this.setState({ billing_detail: new_billing_detail });
  }

  updateClientState(values) {
    const { client } = this.state;
    const new_client = { ...client, ...values };

    this.setState({ client: new_client });
  }

  persistClient() {
    const { client, offer } = this.state;
    const params = { client, public_id: offer.public_id };

    return saveClient(client.id, params);
  }

  persistBillingDetails() {
    const { billing_detail, client, offer } = this.state;
    const params = {
      billing_detail: { ...billing_detail, client_id: client.id },
      public_id: offer.public_id,
    };

    return saveBillingDetail(billing_detail.id, params);
  }

  persistClientOffer() {
    const { billing_detail, offer } = this.state;

    const params = {
      offer: { billing_detail_id: billing_detail.id },
      public_id: offer.public_id,
    };

    return saveClientOffer(offer.id, params);
  }

  persistDataCollectAgreement() {
    return $.ajax({
      type: 'POST',
      url: this.props.offerPolicyUpdatePath,
      data: { storing_booking_data_consented: this.state.form.dataCollectAgree },
    });
  }

  isFormValid() {
    return this.state.form.tosAgree && this.state.form.dataCollectAgree;
  }

  buttonStyles() {
    return classNames(
      'b-button',
      { 'b-button--loading': this.state.form.isLoading },
      { 'b-button--disabled': !this.isFormValid() },
    );
  }

  renderForm() {
    const {
      faqPath,
      termsPath,
      privacyPath,
    } = this.props;

    return (
      <div ref={this.formRef}>
        <h3>{I18n.t('payment_process.billing_data.billing_address')}</h3>
        <BillingForm />
        <ClientInfo />
        <AgreementCheckboxes
          termsPath={termsPath}
          privacyPath={privacyPath}
        />
        <div className="b-payment__buttons">
          <button
            disabled={!this.isFormValid()}
            className={this.buttonStyles()}
            onClick={this.onClickNext}
          >
            {I18n.t('payment_process.billing_data.cta_button')}
          </button>
          <button className="b-button b-button--inverse" type="button" onClick={this.props.onBack}>
            {I18n.t('payment_process.payment_method.back')}
          </button>
        </div>
        <FAQFooter faqPath={faqPath} />
      </div>
    );
  }

  renderBookingInfo() {
    const { offer } = this.state;

    const {
      artist_name,
      artist_service,
    } = offer;

    return (
      <div>
        <h3>{I18n.t('payment_process.billing_data.you_are_booking')}</h3>
        <BookingTotalsField
          fieldName={I18n.t('offer.artist_name')}
          fieldValue={artist_name}
        />
        <BookingTotalsField
          fieldName={I18n.t('offer.service_name')}
          fieldValue={artist_service}
          className="value-light"
        />
        <div className="b-payment__blue-box">
          {this.renderBookingTotals()}
        </div>
      </div>
    );
  }

  renderBookingTotals() {
    const { form, offer } = this.state;
    const { vatValidating, vatExclude } = form;
    const { extraCosts } = this.props;

    if (vatValidating) {
      return (
        <div className="b-payment__blue-box__loading">
          <Spinner />
        </div>
      );
    }

    if (offer.is_full_payment === true) {
      return (
        <FullTotals offer={offer} skip_vat={vatExclude} />
      );
    }

    return (
      <PartialTotals offer={offer} extraCosts={extraCosts} skip_vat={vatExclude} />
    );
  }

  render() {
    return (
      <BillingDataContext.Provider value={this.getContextValue()}>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="b-payment__header">
                <h1>{I18n.t('payment_process.billing_data.title')}</h1>
                <p>{I18n.t('payment_process.billing_data.subtitle')}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-md-offset-2">
              <div className="visible-xs-block visible-sm-block">
                {this.renderBookingInfo()}
              </div>
              {this.renderForm()}
            </div>
            <div className="col-md-4 col-md-offset-1 hidden-xs hidden-sm">
              <ReactSticky.Sticky topOffset={130}>
                {({ style }) => (
                  <div style={style}>
                    {this.renderBookingInfo()}
                  </div>
                )}
              </ReactSticky.Sticky>
            </div>
          </div>
        </div>
      </BillingDataContext.Provider>
    );
  }
}

BillingData.propTypes = {
  client: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  billing_detail: PropTypes.object,
  extraCosts: PropTypes.array.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  faqPath: PropTypes.string.isRequired,
  termsPath: PropTypes.string.isRequired,
  privacyPath: PropTypes.string.isRequired,
  offerPolicyUpdatePath: PropTypes.string.isRequired,
};

module.exports = BillingData;
