const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const ScrollableAnchor = require('react-scrollable-anchor').default;
const { configureAnchors } = require('react-scrollable-anchor');
const MoreInfo = require('../more_info');

class ArtisticServiceItemContainer extends React.Component {
  constructor(props) {
    super(props);

    const { moreInfo } = this.props;

    this.state = { moreInfo };
    this.onClickMoreInfo = this.onClickMoreInfo.bind(this);
  }

  componentWillMount() {
    configureAnchors({ scrollDuration: 400, keepLastAnchorHash: true, offset: -50 });
  }

  componentDidMount() {
    const { slug } = this.props;

    if (window.location.hash.slice(1) === slug) {
      this.onClickMoreInfo();
    }
  }

  onClickMoreInfo() {
    let { moreInfo } = this.state;
    const { disableMoreInfo } = this.props;

    if (disableMoreInfo) return null;

    moreInfo = !moreInfo;

    this.setState({ moreInfo });
    this.props.onExpandChange(moreInfo);
  }

  componentTopClassName() {
    const { moreInfo } = this.state;
    const { id } = this.props;

    return classNames(`b-service qa-artistic-service-${id}`, {
      'b-service--expanded': moreInfo,
    });
  }

  renderMoreInfo() {
    const { moreInfo } = this.state;
    const { sections, name_with_artist } = this.props;

    if (!moreInfo) return null;

    return (
      <div className="row">
        <div className="col-md-10 col-md-offset-1">
          <MoreInfo sections={sections} alt={name_with_artist} />
        </div>
      </div>
    );
  }

  renderMainImage() {
    const { src, alt } = this.props.main_image;

    return (
      <img src={src} alt={alt} />
    );
  }

  render() {
    const {
      anchor,
      name,
      description,
      slug,
      disableAnchor,
      location,
      showLocation,
      disableMoreInfo,
      admin_stats,
    } = this.props;
    const { moreInfo } = this.state;

    return (
      <ScrollableAnchor id={disableAnchor ? '' : slug}>
        <div className={this.componentTopClassName()}>
          <div className="row">
            <div className="col-md-6">
              <div className="b-service__image">
                {this.renderMainImage()}
              </div>
            </div>
            <div className="col-md-6">
              <div className="b-service__description-wrapper">
                <div className="b-service__description">
                  <h2><a href={anchor} onClick={this.onClickMoreInfo}>{name}</a></h2>
                  { showLocation
                    && (
                    <div className="b-recommendations__service-card-location b-recommendations--sm-text-center">
                      <span className="b-recommendations__service-card-location-pin">{location}</span>
                    </div>
                    )}
                  <p>{description}</p>
                  {admin_stats && <p><small>{admin_stats}</small></p>}
                  <div className="b-service__buttons">
                    { !disableMoreInfo
                      && (
                      <button
                        className="b-button b-button--inverse b-button--transparent"
                        onClick={this.onClickMoreInfo}
                        type="button"
                      >
                        {
                          moreInfo
                            ? I18n.t('artistic_service_item.less_info')
                            : I18n.t('artistic_service_item.more_info')
                        }
                      </button>
                      )}
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.renderMoreInfo()}
        </div>
      </ScrollableAnchor>
    );
  }
}

ArtisticServiceItemContainer.propTypes = {
  id: PropTypes.number.isRequired,
  showLocation: PropTypes.bool,
  disableMoreInfo: PropTypes.bool,
  admin_stats: PropTypes.string,
  moreInfo: PropTypes.bool,
  location: PropTypes.string,
};

ArtisticServiceItemContainer.defaultProps = {
  disableMoreInfo: false,
  admin_stats: null,
  moreInfo: false,
  showLocation: false,
  location: '',
};

module.exports = ArtisticServiceItemContainer;
