// When using npm, import Sentry
import * as Sentry from '@sentry/browser';

const { SENTRY_DSN } = window.BASA_ENV;

if (SENTRY_DSN) {
  try {
    Sentry.init({ dsn: SENTRY_DSN });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
