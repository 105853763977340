const React = require('react');
const PropTypes = require('prop-types');
const Tab = require('./Tab');

const Tabs = ({ tabs, selected, onClick }) => (
  <div className="b-tabs">
    {tabs.map((tab) => (
      <Tab
        key={tab.id}
        selected={tab.id === selected}
        text={tab.text}
        link={tab.link}
        onClick={onClick}
      />
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })).isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};

Tabs.defaultProps = {
  selected: null,
  onClick: null,
};

module.exports = Tabs;
