const setupOnboardOverlay = function () {
  if ($('.onboard-target').length && $('.onboard').length && $('.onboard-ran').length == 0) {
    setTimeout(displayOverlay, 3000);
  }

  function displayOverlay() {
    $('html, body').css({
      overflow: 'hidden',
    });

    const setTargetCirclePosition = function (onboardTarget) {
      const targetPos = onboardTarget.offset();
      const targetWidth = onboardTarget.width();
      const targetHeight = onboardTarget.height();

      $('.onboard-overlay .target-circle').offset({
        top: targetPos.top + targetHeight / 2 - 50,
        left: targetPos.left + targetWidth / 2 - 50,
      });

      $('.not-now').offset({
        top: targetPos.top + targetHeight / 2 - 10,
        left: targetPos.left + targetWidth / 2 - 120,
      });
    };

    const setContentPosition = function (onboardTarget) {
      const targetPos = onboardTarget.offset();
      const targetWidth = onboardTarget.width();
      const targetHeight = onboardTarget.height();
      if ($(window).width() <= 768) {
        $('.onboard-overlay .content').css('top',
          targetPos.top + targetHeight / 2 - 400);
        $('.onboard-overlay .content').css('margin', '0 auto');
        $('.onboard-overlay .content').css('left', '0');
        $('.onboard-overlay .content').css('right', '0');
        $('.onboard-overlay .content').width($(window).width() - 100);
      } else {
        $('.onboard-overlay .content').offset({
          top: targetPos.top + targetHeight / 2 - 300,
          left: targetPos.left + targetWidth / 2 - 600,
        });
        $('.onboard-overlay .content').height(400);
        $('.onboard-overlay .content').width(600);
      }
    };

    const setScrollPosition = function (onboardTarget, completeFunction) {
      // Position onboard-target at bottom of the page
      $('html, body').animate({ scrollTop: onboardTarget.offset().top - ($(window).height() - onboardTarget.outerHeight() - 50) }, {
        duration: 500,
        complete: completeFunction,
      });
    };

    $('.onboard').hide().appendTo('body').fadeIn('slow');

    setScrollPosition($('.onboard-target'), () => {
      // On scroll animation complete:
      setTargetCirclePosition($('.onboard-target'));
      setContentPosition($('.onboard-target'));
    });

    $('.onboard').addClass('onboard-ran');

    $(window).on('resize', () => {
      setTargetCirclePosition($('.onboard-target'));
      setContentPosition($('.onboard-target'));
    });

    // listens for click on document and closes onboarding
    $(document).one('click', () => {
      event.preventDefault();
      $('.onboard').hide().fadeOut('slow');
      $('html, body').css({
        overflow: 'scroll',
      });
    });

    // if click is on .onboard-target then follow the link
    $('.onboard-target').click((event) => {
      $('this').click();
      event.stopPropagation();
    });
  }
};

document.addEventListener('turbolinks:load', setupOnboardOverlay);
