const React = require('react');
const PropTypes = require('prop-types');
const OptionalVerticalField = require('./optional_vertical_field');
const VerticalField = require('./vertical_field');
const CancellationPolicy = require('../CancellationPolicy');
const OfferAmountBreakdown = require('./OfferAmountBreakdown');
const { formatCurrency } = require('../../lib/currency');

const ArtistOfferView = ({ offer, extraCosts, pov }) => {
  const formattedGrossAmountBasa = formatCurrency(
    I18n.locale,
    offer.currency,
    offer.gross_amount_basa,
  );

  return (
    <div className="b-offer">
      <h2 className="b-offer__title">
        {I18n.t('negotiation_dashboard.booking_title')}
      </h2>
      <div className="b-offer__fields">
        <VerticalField
          name={I18n.t('inquiry_preview.client_name')}
          value={offer.client_name}
          className="b-offer__field"
        />
        <OptionalVerticalField
          name={I18n.t('inquiry_preview.company_name')}
          value={offer.company_name}
          className="b-offer__field"
        />
        <VerticalField
          name={I18n.t('inquiry_preview.artist_name')}
          value={offer.artist_name}
          className="b-offer__field"
        />
        <VerticalField
          name={I18n.t('inquiry_preview.artistic_service')}
          value={offer.artist_service}
          className="b-offer__field"
        />
        <VerticalField
          name={I18n.t('inquiry_preview.kind_of_moment')}
          value={offer.kind_of_moment}
          className="b-offer__field"
        />
        <VerticalField
          name={I18n.t('inquiry_preview.where')}
          value={offer.where}
          className="b-offer__field"
        />
        <VerticalField
          name={I18n.t('inquiry_preview.date')}
          value={offer.when}
          valueClasses="b-offer__field--bold"
          className="b-offer__field"
        />
        <OptionalVerticalField
          name={I18n.t('inquiry_preview.desired_starting_time')}
          value={offer.show_beginning}
          className="b-offer__field"
        />
        <OptionalVerticalField
          name={I18n.t('inquiry_preview.desired_duration')}
          value={offer.show_duration}
          className="b-offer__field"
        />
        <OptionalVerticalField
          name={I18n.t('inquiry_preview.surface_size')}
          value={offer.surface_size}
          className="b-offer__field"
        />
        <VerticalField
          name={I18n.t('inquiry_preview.details')}
          value={offer.specific}
          className="b-offer__field"
        />
      </div>
      <OfferAmountBreakdown offer={offer} extraCosts={extraCosts} pov={pov} />
      <div style={{ marginTop: 30 }}>
        <CancellationPolicy
          content={offer.cancellation_policy}
          option={offer.cancellation_policy_option}
          pov={pov}
          amountDue={offer.gross_amount_basa}
        />
      </div>
      {pov === 'client' && (
        <VerticalField
          name={I18n.t('negotiation_dashboard.client.due_now')}
          value={formattedGrossAmountBasa}
          nameClasses="b-offer__field--left b-offer__field--black b-offer__field--bold"
          valueClasses="b-offer__field--right b-offer__field--bold"
          className="b-offer__field"
        />
      )}
    </div>
  );
};

ArtistOfferView.propTypes = {
  offer: PropTypes.object.isRequired,
  extraCosts: PropTypes.array.isRequired,
  pov: PropTypes.oneOf(['client', 'artist']).isRequired,
};

module.exports = ArtistOfferView;
