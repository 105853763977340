const React = require('react');
const PropTypes = require('prop-types');
const { saveAdminResponse } = require('../../../../resources/InvitationRequestResource');

const optionInfo = {
  general_no: 'This will send the "General NO" email template',
  capacity_no: 'This will send the "Capacity NO" email template',
  geo_no: 'This will send the "Geo NO" email template',
  art_no: 'This will send the "Art NO" email template',
  material_no: 'This will send the "Material NO" email template',
  manual_no: 'This will not send any email',
};

const optionText = {
  general_no: 'General NO',
  capacity_no: 'Capacity NO',
  geo_no: 'Geo NO',
  art_no: 'Art NO',
  material_no: 'Material NO',
  manual_no: 'Manual NO',
};

const RejectForm = (props) => {
  const [reject_option, setRejectOption] = React.useState('general_no');
  const [sending, setSending] = React.useState(false);
  const {
    admin_responses_no,
    invitation_request_id,
  } = props;

  const onRespond = () => {
    setSending(true);
    saveAdminResponse(invitation_request_id, { admin_response: reject_option }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  const onSelect = (event) => {
    setRejectOption(event.target.value);
  };

  return (
    <form style={{ marginTop: 20 }} className="qa-reject-form">
      <div className="form-group">
        <select onChange={onSelect} value={reject_option}>
          {admin_responses_no.map((response) => (
            <option value={response}>{optionText[response]}</option>
          ))}
        </select>
      </div>
      <p className="alert alert-info">{optionInfo[reject_option]}</p>
      <button
        className="btn btn-default"
        onClick={onRespond}
        disabled={sending}
      >
        Reject
      </button>
    </form>
  );
};

RejectForm.propTypes = {
  invitation_request_id: PropTypes.number.isRequired,
  admin_responses_no: PropTypes.array.isRequired,
};

module.exports = RejectForm;
