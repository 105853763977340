const React = require('react');
const PropTypes = require('prop-types');
const OfferShowField = require('../../offers/show_field');
const PaymentActionsController = require('./payment_actions_controller');
const { formatCurrency } = require('../../../lib/currency');

class BankTransferPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };
  }

  onSubmit = () => {
    const { onNext, reserve_path } = this.props;

    this.setState({ isProcessing: true });

    $.post(reserve_path).done(() => {
      this.setState({ isProcessing: false }, () => onNext());
    });
  };

  render() {
    const { client_email, offer, onBack } = this.props;

    const {
      net_amount_basa,
      vat_percent_basa,
      vat_amount_basa,
      gross_amount_basa,
      total_net_booking_value_with_extra_costs,
      total_gross_booking_value_with_extra_costs,
      total_vat_amount,
      is_full_payment,
      currency,
    } = offer;

    const { isProcessing } = this.state;

    const totalNetBookingValueWithExtraCosts = formatCurrency(
      I18n.locale,
      currency,
      total_net_booking_value_with_extra_costs,
    );
    const totalGrossBookingValueWithExtraCosts = formatCurrency(
      I18n.locale,
      currency,
      total_gross_booking_value_with_extra_costs,
    );
    const netAmountBasa = formatCurrency(
      I18n.locale,
      currency,
      net_amount_basa,
    );
    const grossAmountBasa = formatCurrency(
      I18n.locale,
      currency,
      gross_amount_basa,
    );
    const vatAmountBasa = formatCurrency(
      I18n.locale,
      currency,
      vat_amount_basa,
    );
    const totalVatAmount = formatCurrency(
      I18n.locale,
      currency,
      total_vat_amount,
    );

    return (
      <div className="basa-bankTransferPayment">
        <p className="basa-bankTransferPayment-info1">
          {I18n.t('payment_process.payment_method.please_transfer', {
            email: client_email,
          })}
        </p>
        <div className="basa-bankTransferPayment-fields">
          <OfferShowField
            className="offerShowLabelAmountDue name-dark"
            fieldName={I18n.t('offer.amount_due')}
          />
          {is_full_payment ? (
            <>
              <OfferShowField
                className="offerShowFieldNetAmountBasa value-light"
                fieldName={I18n.t('offer.net_value')}
                fieldValue={totalNetBookingValueWithExtraCosts}
              />
              <OfferShowField
                className="offerShowFieldVatPercentBasa value-light"
                fieldName={I18n.t('offer.basa_vat', { vat: vat_percent_basa })}
                fieldValue={totalVatAmount}
              />
              <OfferShowField
                className="offerShowFieldGrossAmountBasa name-dark"
                fieldName={I18n.t('offer.total_amount_due')}
                fieldValue={totalGrossBookingValueWithExtraCosts}
              />
            </>
          ) : (
            <>
              <OfferShowField
                className="offerShowFieldNetAmountBasa value-light"
                fieldName={I18n.t('offer.net_value')}
                fieldValue={netAmountBasa}
              />
              <OfferShowField
                className="offerShowFieldVatPercentBasa value-light"
                fieldName={I18n.t('offer.vat', { vat: vat_percent_basa })}
                fieldValue={vatAmountBasa}
              />
              <OfferShowField
                className="offerShowFieldGrossAmountBasa name-dark"
                fieldName={I18n.t('offer.total_amount_due')}
                fieldValue={grossAmountBasa}
              />
            </>
          )}
        </div>
        <PaymentActionsController
          handleBackButton={onBack}
          handleSubmit={this.onSubmit}
          isProcessing={isProcessing}
        />
      </div>
    );
  }
}

BankTransferPayment.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  reserve_path: PropTypes.string.isRequired,
  client_email: PropTypes.string.isRequired,
  net_amount_basa: PropTypes.string.isRequired,
  vat_percent_basa: PropTypes.number.isRequired,
  vat_amount_basa: PropTypes.string.isRequired,
  gross_amount_basa: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
};

module.exports = BankTransferPayment;
