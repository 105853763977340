/* eslint-disable import/first */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Polyfills
import '@stimulus/polyfills';
import 'polyfills/ParentNode-append';
import 'custom-event-polyfill';
import 'intersection-observer';

require('../initializers/setup_sentry');

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import de from 'date-fns/locale/de';

registerLocale('pt', pt);
registerLocale('de', de);
setDefaultLocale(I18n.locale);

global.$ = global.jQuery = require('jquery');
require('jquery-ujs');

global.$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  },
});

// Use this to show XXX instead of a translation.
// Show the translation key instead of XXX if the
//   key is missing in .yml file.
//
// const realI18n = window.I18n;
// window.I18n = {};
// window.I18n.t = (foo, bar) => {
//   if (realI18n.lookup(foo)) {
//     return 'XXX';
//   }

//   return foo;
// };

const Turbolinks = require('turbolinks');

Turbolinks.start();

// Stimulus.js;
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
// Stimulus.js

// Custom export from getbootstrap.com
// require('bootstrap');
require('../vendor/bootstrap/modals');
require('../vendor/bootstrap/collapse');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/src/css/core.css');

$.fancybox.defaults.hash = false;

// Don't include stories in application build
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

require('../initializers/slider');
require('../initializers/sidebar');
require('../initializers/setup_dropzone');
require('../initializers/setup_see_more');
require('../initializers/character_counter');
require('../initializers/setup_review_modal');
require('../initializers/setup_search_tag_gtm');
require('../initializers/setup_onboard_overlay');
require('../initializers/setup_object_fit_images');
require('../initializers/setup_discover_more_gtm');
require('../initializers/setup_language_link_click');
require('../initializers/setup_collapsable_content');
require('../initializers/setup_blur_input_on_scroll');
require('../initializers/setup_forwarded_inquiry_rejection_survey');
require('../initializers/setup_turbolinks_hashtag_in_url_compatibility');
require('../initializers/setup_cookies_bar');
require('../initializers/setup_faq');
require('../initializers/setup_lozad');
require('../initializers/setup_client_id_tracking');
require('../initializers/setup_smart_inquiry');
require('../initializers/setup_lazy_react_components');

/**
 * Will be extracted and imported with stylesheet_packs_with_chunks_tag 'application'
 */
import '../stylesheets/application.scss';
