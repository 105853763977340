const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../ui/Modal/index');

const LOCALE_PREFIX_MAP = {
  en: '',
  de: '/de',
  pt: '/pt',
};

const SeeInquiryModal = ({ clickSelector, bookings }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [bookingId, setBookingId] = React.useState(null);

  React.useEffect(() => {
    const clickElements = document.querySelectorAll(clickSelector);

    // eslint-disable-next-line no-restricted-syntax
    for (const clickElement of clickElements) {
      clickElement.addEventListener('click', (e) => {
        setIsOpen(true);
        setBookingId(e.target.dataset.bookingid);
      });
    }
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const booking = bookingId === null
    ? null
    : bookings.find((b) => b.id === parseInt(bookingId, 10));

  const inquiry = booking ? booking.inquiry : null;

  if (!booking || !inquiry) return null;

  const date = inquiry.date || inquiry.occasion_date;

  const localePrefix = LOCALE_PREFIX_MAP[I18n.locale] || '';

  const acceptLink = `${localePrefix}/forwarded_inquiry/${booking.id}/accept`;
  const rejectLink = `${localePrefix}/forwarded_inquiry/${booking.id}/reject`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="b-artist-inquiry">
        <h3 className="b-artist-inquiry__title">
          {I18n.t('artistic_services.dashboard.inquiry.title', {
            name: inquiry.full_name || '',
          })}
        </h3>
        <p className="b-artist-inquiry__description">
          {I18n.t('artistic_services.dashboard.inquiry.description')}
        </p>

        <dl className="b-artist-inquiry__list">
          <dt className="b-artist-inquiry__term">
            {I18n.t('inquiry_preview.where')}
          </dt>
          <dd className="b-artist-inquiry__data b-artist-inquiry__data--highlighted">
            {inquiry.city || ''}
          </dd>
          <dt className="b-artist-inquiry__term">
            {I18n.t('inquiry_preview.client_name')}
          </dt>
          <dd className="b-artist-inquiry__data">{inquiry.full_name || ''}</dd>
          {!!inquiry.company_name && (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.company_name')}
              </dt>
              <dd className="b-artist-inquiry__data">{inquiry.company_name}</dd>
            </>
          )}
          {!!inquiry.company_name && (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.company_name')}
              </dt>
              <dd className="b-artist-inquiry__data">{inquiry.company_name}</dd>
            </>
          )}
          {!!inquiry.kind_of_moment && (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.kind_of_moment')}
              </dt>
              <dd className="b-artist-inquiry__data">
                {I18n.t(`kinds_of_moment.${inquiry.kind_of_moment}`)}
              </dd>
            </>
          )}
          {!!booking.artistic_service && !!booking.artistic_service.name && (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.artistic_service')}
              </dt>
              <dd className="b-artist-inquiry__data">
                {booking.artistic_service.name}
              </dd>
            </>
          )}
          {!!date && (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.date_of_event')}
              </dt>
              <dd className="b-artist-inquiry__data b-artist-inquiry__data--highlighted">
                {I18n.l('date.formats.long', date)}
              </dd>
            </>
          )}
          {!!booking.description && (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.additional_booking_details')}
              </dt>
              <dd className="b-artist-inquiry__data">{booking.description}</dd>
            </>
          )}
          {inquiry.price_range ? (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.estimated_budget')}
              </dt>
              <dd className="b-artist-inquiry__data">{inquiry.price_range}</dd>
            </>
          ) : (
            <>
              <dt className="b-artist-inquiry__term">
                {I18n.t('inquiry_preview.estimated_budget')}
              </dt>
              <dd className="b-artist-inquiry__data">
                {I18n.t('inquiry_preview.not_provided')}
              </dd>
            </>
          )}
        </dl>

        <div className="b-artist-inquiry__actions">
          <a
            className="b-artist-inquiry__cta b-button b-button--inverse"
            type="button"
            href={rejectLink}
            target="_blank"
            rel="noreferrer"
          >
            {I18n.t('artistic_services.dashboard.inquiry.reject')}
          </a>
          <a
            className="b-artist-inquiry__cta b-button b-button--primary"
            type="button"
            href={acceptLink}
            target="_blank"
            rel="noreferrer"
          >
            {I18n.t('artistic_services.dashboard.inquiry.accept')}
          </a>
        </div>
      </div>
    </Modal>
  );
};

SeeInquiryModal.propTypes = {
  clickSelector: PropTypes.string.isRequired,
  bookings: PropTypes.array.isRequired,
};

SeeInquiryModal.defaultProps = {};

module.exports = SeeInquiryModal;
