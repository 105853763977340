/* Usage:
  <AlternativeNegotiationChat
    origin="client"
    userName={forwardedInquiry.artist_name}
    messages={messages}
    messagingPath={messagingPath}
    placeholder={I18n.t('negotiation_dashboard.client.messenger_placeholder')}
  />
*/

const React = require('react');
const ReactDOM = require('react-dom');
const classNames = require('classnames');
const { ampEvent } = require('lib/amplitude');
const Message = require('./message');
const AttachIcon = require('./attach-icon');
const Spinner = require('../icons/spinner');
const { setupDropzoneAttachments } = require('../../initializers/setup_dropzone');
const { dataLayer } = require('../../lib/analytics');

class AlternativeNegotiationChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: props.messages,
      inputValue: '',
      isSending: false,
      error: '',
    };

    this.submitMessage = this.submitMessage.bind(this);
    this.postMessage = this.postMessage.bind(this);
    this.fetchMessages = this.fetchMessages.bind(this);
    this.disableSendingMessages = this.disableSendingMessages.bind(this);
    this.enableSendingMessages = this.enableSendingMessages.bind(this);
    this.cleanInputValue = this.clearInputValue.bind(this);
    this.setError = this.setError.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidMount() {
    // this.scrollToBottom();

    setupDropzoneAttachments($(this.dropzoneNode), {
      onError: this.onAttachmentError,
      onRemovedFile: this.onAttachmentRemoved,
      onSuccess: this.onAttachmentSuccess,
    });
    this.dropzone = window.Dropzone.forElement('.dropzone-attachments');
    this.rerender = () => this.forceUpdate();
    window.Dropzone.prototype.events.forEach((event) => {
      this.dropzone.on(event, this.rerender);
    });
  }

  componentWillUnmount() {
    window.Dropzone.prototype.events.forEach((event) => {
      this.dropzone.off(event, this.rerender);
    });
  }

  onAttachmentSuccess = () => {
    this.setError('');
  }

  onAttachmentRemoved = () => {
    this.setError('');
  }

  onAttachmentError = (message) => {
    this.setError(message);
  }

  isAttaching() {
    const $attachment = $('.dropzone-attachments .dz-preview');

    if ($attachment.length === 0) {
      return false;
    }

    return $attachment.hasClass('dz-processing') && !$attachment.hasClass('dz-complete');
  }

  hasAttachments() {
    return $('.dropzone-attachments .dz-preview').length > 0;
  }

  restart() {
    const {
      onSendChatMessage,
    } = this.props;

    this.fetchMessages().done((messages) => {
      this.enableSendingMessages();
      this.setError('');
      onSendChatMessage(messages);
      // this.scrollToBottom()
    }).fail(() => {
      this.setError(I18n.t('negotiation_chat.errors.failed'));
    });
  }

  postMessage(msg = '') {
    return $.post(this.props.messagingPath, {
      negotiation_message: {
        text: msg,
        origin_type: this.props.origin,
        negotiation_attachment_attributes: {
          id: $('#negotiation_message_negotiation_attachment_attributes_id').val(),
        },
      },
    });
  }

  fetchMessages() {
    return $.get(this.props.messagingPath);
  }

  scrollToBottom() {
    const node = ReactDOM.findDOMNode(this.messagesEnd);
    node.parentNode.scrollTop = node.offsetTop;
  }

  disableSendingMessages(callback) {
    this.setState({ isSending: true }, callback);
  }

  enableSendingMessages() {
    this.setState({ isSending: false });
  }

  setError(errorText) {
    this.setState({ error: errorText });
  }

  submitMessage(event) {
    // Send tag to Hotjar
    hj('tagRecording', ['submit_message_chat_click']);
    dataLayer.push({
      event: 'messageToClient',
    });

    ampEvent('artist_dashboard: messageToClient');
    event.preventDefault();
    if (this.state.inputValue.length === 0 && !this.hasAttachments()) {
      this.setError(I18n.t('negotiation_chat.errors.no_message'));
    } else if (this.isAttaching()) {
      this.setError(I18n.t('negotiation_chat.errors.attaching'));
    } else {
      this.disableSendingMessages(() => {
        this.postMessage(this.state.inputValue).done(() => {
          this.clearInputValue();
          this.restart();
        }).fail(() => {
          this.enableSendingMessages();
          this.setError(I18n.t('negotiation_chat.errors.failed_sending'));
        });
      });
    }
  }

  updateInputValue(event) {
    this.setState({ inputValue: event.target.value });
  }

  clearInputValue() {
    $('#negotiation_message_negotiation_attachment_attributes_id').val('');
    $('.b-chat__input').removeClass('b-chat__input-with_attach');
    window.Dropzone.forElement('.dropzone-attachments').removeAllFiles();

    this.setState({
      error: '',
      inputValue: '',
    });
  }

  render() {
    const {
      origin,
      userName,
      placeholder,
    } = this.props;

    return (
      <div className="b-chat" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="b-chat__input">
          <div ref={(node) => this.dropzoneNode = node} className="dropzone-attachments b-negotiation-attachment drag-and-drop-box dz-clickable">
            <input
              className="negotiation_attachment-id"
              type="hidden"
              name="negotiation_message[negotiation_attachment_attributes][id]"
              id="negotiation_message_negotiation_attachment_attributes_id"
            />
            <input
              className="negotiation_attachment-url"
              type="hidden"
              value=""
              name="negotiation_message[negotiation_attachment_attributes][url]"
              id="negotiation_message_negotiation_attachment_attributes_url"
            />
            <div className={classNames('b-negotiation-attachment__icon-wrapper dz-default dz-message', { 'b-negotiation-attachment__icon-wrapper--is-attaching': this.isAttaching() })}>
              {this.isAttaching() ? <Spinner /> : <AttachIcon />}
            </div>
          </div>
          <textarea className="b-chat__input-text" placeholder={placeholder} value={this.state.inputValue} onChange={(event) => this.updateInputValue(event)} />
        </div>
        {this.state.error !== '' && (
          <div className="b-chat__error">{this.state.error}</div>
        )}
        <button
          type="button"
          onClick={this.submitMessage}
          className={`b-button b-button--inverse ${this.state.isSending ? 'b-button--loading' : ''}`}
          style={{
            margin: '0',
            alignSelf: 'flex-end',
            marginTop: '15px',
            width: '150px',
          }}
        >
          {I18n.t('negotiation_dashboard.messenger.send_message')}
        </button>
      </div>
    );
  }
}

module.exports = AlternativeNegotiationChat;
