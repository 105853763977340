const React = require('react');
const PropTypes = require('prop-types');
const { saveAdminResponse } = require('../../../../resources/InvitationRequestResource');

const optionInfo = {
  material_incomplete: 'This will send the "More material" email template',
  portfolio_incomplete: 'This will send the "Missing portfolio" email template',
  manual_incomplete: 'This will not send any email',
};

const optionText = {
  material_incomplete: 'Material Incomplete',
  portfolio_incomplete: 'Portfolio Incomplete',
  manual_incomplete: 'Manual Incomplete',
};

const IncompleteForm = (props) => {
  const [incomplete_option, setIncompleteOption] = React.useState('material_incomplete');
  const [sending, setSending] = React.useState(false);
  const {
    admin_responses_incomplete,
    invitation_request_id,
  } = props;

  const onRespond = () => {
    setSending(true);
    saveAdminResponse(invitation_request_id, { admin_response: incomplete_option }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  const onSelect = (event) => {
    setIncompleteOption(event.target.value);
  };

  return (
    <form style={{ marginTop: 20 }} className="qa-incomplete-form">
      <div className="form-group">
        <select onChange={onSelect} value={incomplete_option}>
          {admin_responses_incomplete.map((response) => (
            <option value={response}>{optionText[response]}</option>
          ))}
        </select>
      </div>
      <p className="alert alert-info">{optionInfo[incomplete_option]}</p>
      <button
        className="btn btn-default"
        onClick={onRespond}
        disabled={sending}
      >
        Respond
      </button>
    </form>
  );
};

IncompleteForm.propTypes = {
  invitation_request_id: PropTypes.number.isRequired,
  admin_responses_incomplete: PropTypes.array.isRequired,
};

module.exports = IncompleteForm;
