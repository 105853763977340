const React = require('react');
const PropTypes = require('prop-types');

function CreditCardConfirmation({
  client,
  bookingNumber,
  bookingValue,
  paymentStatus,
}) {
  const is_paid = paymentStatus === 'paid_with_cc';

  return (
    <div>
      <h1 className="basa-offersConfirmation-h1">
        {
          is_paid
            ? I18n.t('payment_process.confirmation.congratulations', { name: client.full_name })
            : I18n.t('payment_process.confirmation.we_are_processing')
        }
      </h1>
      <h2 className="basa-offersConfirmation-h2">{I18n.t('payment_process.confirmation.booking_number', { number: bookingNumber })}</h2>
      {is_paid && <p className="basa-offersConfirmation-p">{I18n.t('payment_process.confirmation.your_artist_is_booked')}</p>}
      <p className="basa-offersConfirmation-p">
        {I18n.t('payment_process.confirmation.confirmed_payment', { email: client.email })}
      </p>
      <a className="basa-offersConfirmation-button" href="/search">{I18n.t('payment_process.confirmation.cta_button')}</a>
      <div id="payment-booking-value" data-booking-value={bookingValue} />
    </div>
  );
}

CreditCardConfirmation.propTypes = {
  client: PropTypes.object.isRequired,
  bookingNumber: PropTypes.string.isRequired,
  bookingValue: PropTypes.string.isRequired,
  paymentStatus: PropTypes.string.isRequired,
};

module.exports = CreditCardConfirmation;
