import React from 'react';

const SvgComponent = (props) => (
  <svg width={44} height={37} {...props}>
    <title>icn_contact_general</title>
    <defs>
      <path id="icn_contact_general_prefix__a" d="M0 0h44v37H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M21.613 23.77c.544-.55 1.633-.55 2.177 0 .311.314.467.707.467 1.1 0 .392-.156.784-.467 1.098-.31.314-.7.471-1.089.471-.388 0-.777-.157-1.088-.47a1.557 1.557 0 01-.467-1.1c0-.392.156-.785.467-1.099zM22.7 9.875c2.8 0 5.133 2.276 5.133 5.338 0 2.277-1.555 4.24-3.577 4.867v.785c0 .864-.7 1.57-1.556 1.57a1.567 1.567 0 01-1.555-1.57v-2.198c0-.863.7-1.57 1.555-1.57 1.167 0 2.023-.942 2.023-2.04a2.017 2.017 0 00-2.023-2.042c-1.166 0-2.022.942-2.022 2.041 0 .864-.7 1.57-1.555 1.57a1.567 1.567 0 01-1.556-1.57c0-2.905 2.334-5.181 5.133-5.181z"
        fill="#B9B9B9"
        fillRule="nonzero"
      />
      <g>
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#icn_contact_general_prefix__a" />
        </mask>
        <path
          d="M22 2.253c-10.926 0-19.814 7.172-19.814 15.987 0 2.452.693 4.825 2.058 7.052l.252.411-2.262 9.044 9.234-2.984.386.186c3.082 1.492 6.59 2.28 10.146 2.28 10.926 0 19.814-7.172 19.814-15.989 0-8.815-8.888-15.987-19.814-15.987M2.234 37c-.564 0-1.1-.222-1.507-.622a2.3 2.3 0 01-.61-2.192l2.029-8.11C.72 23.601 0 20.968 0 18.24 0 8.183 9.869 0 22 0s22 8.183 22 18.24c0 10.059-9.869 18.243-22 18.243-3.726 0-7.41-.798-10.684-2.31l-8.428 2.723a2.098 2.098 0 01-.654.104"
          fill="#B9B9B9"
          mask="url(#prefix__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
