const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Arrow = require('../../icons/arrow');
const WithBillingDataContext = require('./with-billing-data-context');

class ClientInfo extends React.Component {
  constructor(props) {
    super(props);

    this.onChangeField = this.onChangeField.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  onChangeField(field, value) {
    this.props.context.updateClientState({ [field]: value });
  }

  errorMessage(field) {
    const errors = this.props.context.errors.client[field];

    return errors && errors[0];
  }

  fieldValueClassName(field) {
    return classNames('b-input-field__value', { 'b-input-field__value--error': this.errorMessage(field) });
  }

  toggleForm() {
    const { clientFormFolded } = this.props.context.form;

    this.props.context.updateFormState({ clientFormFolded: !clientFormFolded });
  }

  renderError(field) {
    const message = this.errorMessage(field);

    if (!message) return null;

    return (
      <div className="b-input-field__error">
        {message}
      </div>
    );
  }

  renderField(field, translation_key) {
    return (
      <div className="b-payment__form__field">
        <div className="b-input-field__name">
          {I18n.t(`offer.${translation_key}`)}
        </div>
        <input
          className={this.fieldValueClassName(field)}
          name={`client_${field}`}
          value={this.props.context.client[field]}
          onChange={(event) => this.onChangeField(field, event.target.value)}
        />
        {this.renderError(field)}
      </div>
    );
  }

  render() {
    const { clientFormFolded } = this.props.context.form;

    return (
      <div className={classNames('b-payment__toggle', { 'b-payment__toggle--open': !clientFormFolded })}>
        <div className="b-payment__toggle__header qa-toggle-client-form" role="button" onClick={this.toggleForm}>
          {I18n.t('payment_process.billing_data.client_form_toggle')}
          <div className="b-payment__toggle__arrow">
            <Arrow />
          </div>
        </div>
        <div className="b-payment__toggle__body b-payment__form">
          {this.renderField('full_name', 'client_name')}
          {this.renderField('email', 'client_email')}
          {this.renderField('phone', 'client_phone')}
        </div>
      </div>
    );
  }
}

ClientInfo.propTypes = {
  context: PropTypes.object.isRequired,
};

module.exports = WithBillingDataContext(ClientInfo);
