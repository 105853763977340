const React = require('react');
const PropTypes = require('prop-types');
const OfferShowField = require('./show_field');
const { formatCurrency } = require('../../lib/currency');

function BookingAmounts({
  gross_amount_basa,
  vat_percent_basa,
  vat_amount_basa,
  gross_amount_artist_with_extra_costs,
  total_gross_booking_value_with_extra_costs,
  currency,
}) {
  const totalGrossBookingValueWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    total_gross_booking_value_with_extra_costs,
  );
  const grossAmountBasa = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_basa,
  );
  const vatAmountBasa = formatCurrency(I18n.locale, currency, vat_amount_basa);
  const grossAmountArtistWithExtraCosts = formatCurrency(
    I18n.locale,
    currency,
    gross_amount_artist_with_extra_costs,
  );
  return (
    <div className="basa-offersShow-totals">
      {+gross_amount_artist_with_extra_costs > 0 && (
        <OfferShowField
          className="value-light"
          fieldName={I18n.t('offer.total_booking_value')}
          fieldValue={totalGrossBookingValueWithExtraCosts}
        />
      )}
      <OfferShowField
        className="name-dark"
        fieldName={I18n.t('offer.amount_due_now')}
        fieldValue={grossAmountBasa}
      />
      <OfferShowField
        className="value-light"
        fieldName={I18n.t('offer.vat', { vat: vat_percent_basa })}
        fieldValue={vatAmountBasa}
      />
      {+gross_amount_artist_with_extra_costs > 0 && (
        <OfferShowField
          className="value-light"
          fieldName={I18n.t('offer.remaining_amount')}
          fieldValue={grossAmountArtistWithExtraCosts}
        />
      )}
    </div>
  );
}

BookingAmounts.propTypes = {
  gross_amount_basa: PropTypes.string.isRequired,
  vat_percent_basa: PropTypes.number.isRequired,
  vat_amount_basa: PropTypes.string.isRequired,
  gross_amount_artist_with_extra_costs: PropTypes.string.isRequired,
  total_gross_booking_value_with_extra_costs: PropTypes.string.isRequired,
};

module.exports = BookingAmounts;
