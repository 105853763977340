const React = require('react');
const PropTypes = require('prop-types');
const Tab = require('./Tab');
const ApproveForm = require('./ApproveForm');
const RejectForm = require('./RejectForm');
const IncompleteForm = require('./IncompleteForm');

const AdminResponseForm = (props) => {
  const [tab, setTab] = React.useState('approve');

  const {
    invitation_request_id,
    admin_responses_yes,
    admin_responses_no,
    admin_responses_incomplete,
  } = props;

  const onClickTab = (currentTab) => {
    setTab(currentTab);
  };

  return (
    <>
      <ul className="nav nav-tabs">
        <Tab name="approve" currentTab={tab} text="Approve" onClick={onClickTab} />
        <Tab name="reject" currentTab={tab} text="Reject" onClick={onClickTab} />
        <Tab name="incomplete" currentTab={tab} text="Incomplete" onClick={onClickTab} />
      </ul>
      {(tab === 'approve')
      && (
      <ApproveForm
        invitation_request_id={invitation_request_id}
        admin_responses_yes={admin_responses_yes}
      />
      )}
      {(tab === 'reject')
      && (
      <RejectForm
        invitation_request_id={invitation_request_id}
        admin_responses_no={admin_responses_no}
      />
      )}
      {(tab === 'incomplete')
      && (
      <IncompleteForm
        invitation_request_id={invitation_request_id}
        admin_responses_incomplete={admin_responses_incomplete}
      />
      )}
    </>
  );
};

AdminResponseForm.propTypes = {
  invitation_request_id: PropTypes.number.isRequired,
  admin_responses_yes: PropTypes.array.isRequired,
  admin_responses_no: PropTypes.array.isRequired,
  admin_responses_incomplete: PropTypes.array.isRequired,
};

module.exports = AdminResponseForm;
