const React = require('react');
const PropTypes = require('prop-types');
const ReactTagsInput = require('react-tagsinput');
const Autosuggest = require('react-autosuggest');

class ServiceTagsInput extends React.Component {
  constructor(props) {
    super();
    this.state = { suggestions: [], value: '' };
    this.focusAutosuggest = this.focusAutosuggest.bind(this);
    this.loadSuggestions = this.loadSuggestions.bind(this);
    this.storeInputReference = this.storeInputReference.bind(this);
    this.handlePasteSplit = this.handlePasteSplit.bind(this);
  }

  focusAutosuggest() {
    this.input.focus();
  }

  handlePasteSplit(data) {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
    return data.split(new RegExp(separators.join('|'))).map((d) => d.trim());
  }

  // This functions waits for the user to stop typing to make the request,
  // we compare the current search text with the current value in the state.
  // if they match means user have stopped typping and we should perform the request
  // else theyre still typing
  loadSuggestions(text) {
    window.setTimeout(
      () => {
        // check if current value in input is the same (if not, the user might
        // be typing its query)
        if (this.state.value == text) {
          $.get({
            url: '/tags.json',
            data: {
              q: text,
              locale: I18n.currentLocale(),
            },
          })
            .done((data) => {
              this.setState({ suggestions: data });
            })
            .fail((data) => {
              console.log('error');
            });
        }
      },
      300,
    );
  }

  storeInputReference(autosuggest) {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  }

  render() {
    const triggerKeysMap = {
      13: 'ENTER',
      186: 'SEMI-COLON',
      188: 'COMMA',
    };

    const triggerKeys = Object.keys(triggerKeysMap).map((k) => Number(k));

    const { suggestions } = this.state;
    function autocompleteRenderInput({ addTag, ...props }) {
      const handleOnChange = (e, { newValue, method }) => {
        if (method === 'enter') {
          e.preventDefault();
        } else {
          this.setState({ value: newValue });
          props.onChange(e);
        }
      };

      const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
      const inputLength = inputValue.length;

      return (
        <Autosuggest
          ref={this.storeInputReference}
          suggestions={suggestions}
          shouldRenderSuggestions={(value) => value && value.trim().length > 0}
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={(suggestion) => <span>{suggestion}</span>}
          inputProps={{ ...props, onChange: handleOnChange }}
          onSuggestionSelected={(e, { suggestion }) => {
            addTag(suggestion);
          }}
          onSuggestionsClearRequested={() => { }}
          onSuggestionsFetchRequested={({ value }) => this.loadSuggestions(value)}
        />
      );
    }

    return (
      <div onClick={this.focusAutosuggest}>
        <ReactTagsInput
          ref="tagInput"
          inputProps={{ placeholder: I18n.t('artistic_services.keywords_placeholder') }}
          renderInput={autocompleteRenderInput.bind(this)}
          value={this.props.value}
          onChange={this.props.handleChange}
          pasteSplit={this.handlePasteSplit}
          addOnPaste
          addKeys={triggerKeys}
        />
      </div>
    );
  }
}

module.exports = ServiceTagsInput;
