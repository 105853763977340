const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('../../../ui/Modal');
const Checkbox = require('../../../ui/Checkbox');
const { saveTipsGroup } = require('../../../../resources/TipsGroupsResource');

const HowToVideoModal = ({
  isOpen,
  closeModal,
  tipsGroup,
  booking_public_id,
}) => {
  const [
    hide_vartman_video,
    setHideVartmanVideo,
  ] = React.useState(tipsGroup.hide_vartman_video || false);

  const saveAndClose = () => {
    if (!tipsGroup.id) return closeModal();

    return saveTipsGroup(tipsGroup.id, {
      tips_group: { hide_vartman_video },
      booking_public_id,
    }).then(() => {
      closeModal();
    });
  };

  // TODO: Dehardcode the youtube link in favor of an ActiveAdmin setting?
  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
    >
      <div
        className="b-react-modal__title"
        style={{
          color: '#F7C95C', textTransform: 'uppercase', fontSize: 24, marginBottom: 10,
        }}
      >
        {I18n.t('negotiation_dashboard.how_to_modal.title')}
      </div>
      <div style={{ fontSize: 14 }}>
        <p>{I18n.t('negotiation_dashboard.how_to_modal.subtitle')}</p>
        <iframe
          title="howto-video"
          className="b-react-modal__body__video"
          src="https://www.youtube.com/embed/jbYo3dVsUSg?rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <div style={{ marginTop: 20, textAlign: 'center', fontWeight: 300 }}>
          <Checkbox
            name="hide_vartman_video"
            label={I18n.t('negotiation_dashboard.how_to_modal.hide')}
            checked={hide_vartman_video}
            onChange={(_name, checked) => setHideVartmanVideo(checked)}
          />
        </div>
      </div>
      <div className="b-react-modal__footer">
        <button onClick={saveAndClose} className="b-button">
          {I18n.t('negotiation_dashboard.how_to_modal.button')}
        </button>
      </div>
    </Modal>
  );
};

HowToVideoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  tipsGroup: PropTypes.object.isRequired,
  booking_public_id: PropTypes.string.isRequired,
};

module.exports = HowToVideoModal;
