const React = require('react');
const { formatDate } = require('../../lib/datetime_formatters');

const fileExtension = (filePath) => {
  const extLimit = 5;
  const ext = filePath.split('.').pop();

  if (ext.length <= 0) {
    return 'FILE';
  } if (ext.length > extLimit) {
    return ext.substring(0, extLimit);
  }

  return ext;
};

class Message extends React.Component {
  messageTitle() {
    const {
      date,
      isOwner,
      senderName,
      overview,
    } = this.props;

    const formattedDate = formatDate(date, 'dd LLLL yyyy');
    const formattedTime = formatDate(date, 'HH:mm');

    return (
      isOwner && !overview
        ? I18n.t(
          'negotiation_chat.message.from_owner',
          {
            date: formattedDate,
            time: formattedTime,
          },
        )
        : I18n.t(
          'negotiation_chat.message.from_others',
          {
            date: formattedDate,
            time: formattedTime,
            name: senderName,
          },
        )
    );
  }

  formatText() {
    const {
      text,
    } = this.props;

    const splitTxt = text.split('\n');

    return splitTxt.map((item, key) => {
      return <span key={key}>{item}<br /></span>;
    });
  }

  render() {
    const {
      isOwner,
      attachment,
    } = this.props;

    return (
      <div className="b-message">
        <p className={`b-message__date ${isOwner ? 'b-message__date--right' : ''}`}>
          {this.messageTitle()}
        </p>
        {this.props.text.length > 0 && (
        <div className={`b-message__bubble ${isOwner ? 'b-message__bubble--dark' : ''}`}>
          <div>{this.formatText()}</div>
        </div>
        )}
        {attachment !== undefined
          && (
          <div className={`b-message__attachment ${isOwner ? 'b-message__attachment--right' : ''}`}>
            <a className="b-message__attachment-link" href={attachment.file.url} target="_blank" rel="noreferrer">
              <img
                className="b-message__attachment-preview"
                src={attachment.file.thumbnail.url}
                alt=""
              />
              <div className="b-message__attachment-placeholder">
                {fileExtension(attachment.file.url)}
              </div>
            </a>
          </div>
          )}
      </div>
    );
  }
}

module.exports = Message;
