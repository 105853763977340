const React = require('react');
const BillingDataContext = require('./context');

module.exports = (Component) => class extends React.Component {
  render() {
    return (
      <BillingDataContext.Consumer>
        {(context) => <Component {...{ ...this.props, context }} />}
      </BillingDataContext.Consumer>
    );
  }
};
