import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import Layout from '../../../ui/Layout';

const isOtherReason = (reason) => reason === 'other';
const not = (f) => (...args) => !f(...args);

class Survey extends React.Component {
  state = {
    selectedReason: null,
    optional: null,
  }

  render() {
    const {
      onSkip,
      onSubmitSurvey,
      reasons,
    } = this.props;

    const orderedReasons = [...reasons.filter(not(isOtherReason)), 'other'];

    return (
      <div>
        <Heading text={I18n.t('booking_cancelation.reasons.title')} />
        <p>{I18n.t('booking_cancelation.reasons.description')}</p>
        {orderedReasons.map((reason) => (
          <>
            <div className="b-survey__radio-button">
              <input
                id={`survey_${reason}`}
                name="cancelation_survey"
                type="radio"
                onChange={() => this.setState({ selectedReason: reason })}
                value={reason}
              />
              <label htmlFor={`survey_${reason}`}>
                {I18n.t(`booking_cancelation.reasons.list.${reason}`)}
              </label>
            </div>
            {isOtherReason(reason) && (
              <div className="b-survey__indented">
                <input
                  disabled={!isOtherReason(this.state.selectedReason)}
                  value={this.state.optional}
                  onChange={(event) => this.setState({ optional: event.target.value })}
                  className="b-textinput"
                  placeholder={I18n.t('booking_cancelation.reasons.other_placeholder_artist')}
                  type="text"
                  name="survey[optional]"
                  id="survey_optional"
                />
              </div>
            )}
          </>
        ))}
        <br />
        <Layout.List>
          <Button primary onClick={() => onSubmitSurvey({ reason: this.state.selectedReason, optional: this.state.optional })}>{I18n.t('shared.submit')}</Button>
          <Button inverse onClick={onSkip}>{I18n.t('shared.skip')}</Button>
        </Layout.List>
      </div>
    );
  }
}

Survey.defaultProps = {
  onSkip: () => {},
  onSubmitSurvey: () => {},
};

Survey.propTypes = {
  onSkip: PropTypes.func,
  onSubmitSurvey: PropTypes.func,
  reasons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Survey;
